import React from "react";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import MessageCenterModelNameBar from "./messages-model-name-bar";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import MessageStore from "./stores/messages/MessageStore";

type Props = {
  conversation: MessageCenterConversationDetails;
  modelProfileImage: string;
  onSharedMediaButtonClicked: () => void;
  languageStore?: LanguageStore;
  messageStore?: MessageStore;
};

const MessageCenterReaderModelBar: React.ComponentType<Props> = ({
  conversation,
  modelProfileImage,
  onSharedMediaButtonClicked,
  languageStore,
  messageStore,
}) => {
  const { intl } = languageStore!;
  const { isCSAgentChatActive } = messageStore!;
  return (
    <MessageCenterModelNameBar
      conversation={conversation}
      modelProfileImage={modelProfileImage}
      buttonChildren={
        <TailwindFlex width='w-auto'>
          <TailwindTooltip
            content={intl.formatMessage({
              id: "message-center.shared-conversation",
              defaultMessage: "Shared Media",
            })}>
            <TailwindFlex alignItems='items-center'>
              {isCSAgentChatActive && (
                <TailwindText className='font-semibold'>History</TailwindText>
              )}
              <TailwindButton
                onClick={onSharedMediaButtonClicked}
                fullWidth={false}
                padding={["p-0"]}
                margin={["ml-3"]}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                backgroundColor={"bg-tertiary-bg-color"}
                height={"h-10"}
                width={"w-10"}
                borderRadius={"rounded-full"}
                rightIconProps={{
                  name: "shared-media-icon",
                  fontSize: "text-lg",
                  textColor: "text-secondary-color",
                  borderRadius: "rounded-full",
                  style: {
                    position: "relative",
                  },
                }}
              />
            </TailwindFlex>
          </TailwindTooltip>
        </TailwindFlex>
      }
    />
  );
};

export default inject(
  "languageStore",
  "messageStore"
)(observer(MessageCenterReaderModelBar));
