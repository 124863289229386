import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import {
  BROADCAST_MODAL_OBS_INVALID_SETTINGS_NAME,
  BROADCAST_NO_MICROPHONES_AVAILABLE_ERROR,
  BROADCAST_NO_WEBCAMS_AVAILABLE_ERROR,
  INITIAL_DATA,
  NANOPLAYER_ELEMENT_ID,
  WOWZA_DEFAULT_WEBRTC_SETTINGS,
  WOWZA_MAX_VIDEO_BITRATE,
  JUST_STOPPED_INTERVAL,
} from "common/broadcast/_stores/broadcast-stream/consts";
import {
  BroadcastError,
  BroadcastErrorAction,
  BroadcastOBSVideoSettings,
  BroadcastOBSVideoSettingsValidation,
  BroadcastPeerConnectionListeners,
  BroadcastStreamCredentials,
  BroadcastStreamStat,
  BroadcastStreamStats,
  BroadcastStreamViewerCredentials,
  BroadcastVideoResolutionOption,
  BroadcastWebRTCExtendedMediaDeviceInfo,
  BroadcastWebRTCPeerConnectionConfig,
  BroadcastWebRTCWebsocketListeners,
  NanoPlayerSettings,
  WowzaWebRTCStreamSettings,
} from "common/broadcast/_stores/broadcast-stream/types";
import { logger } from "library/core/utility";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import config from "core/config";
import { broadcastStreamService } from "common/broadcast/_stores/broadcast-stream/BroadcastStreamService";
import CamsNanoPlayer from "core/utility/nano/nano";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";
import { broadcastStrategy } from "common/broadcast/_stores/BroadcastStrategy";
import {
  broadcastStore,
  broadcastStreamStore,
  logToGraylog,
  nodeChatStore,
  profileStore,
} from "core/stores";
import { parseResolutionString } from "common/broadcast/_stores/broadcast-stream/utils";
import { modalStore } from "library/core/stores/modal";
import BroadcastInvalidObsSettingsError from "common/broadcast/broadcast-custom-errors/broadcast-invalid-obs-settings-error";
import React from "react";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { timeoutPromise } from "core/utility/misc";

const logPrefix = "[BroadcastStreamStore]:";

export default class BroadcastStreamStore {
  mediaStream: MediaStream | null = INITIAL_DATA.controlledMediaStream;
  nanoPlayer: CamsNanoPlayer | null = INITIAL_DATA.nanoPlayer;
  nanoPlayerSettings: NanoPlayerSettings | null =
    INITIAL_DATA.nanoPlayerSettings;
  startedWebRTCBroadcasting: boolean = INITIAL_DATA.startedWebRTCBroadcasting;
  streamType: BroadcastStreamType | null = INITIAL_DATA.streamType;
  obsWebrtcPlaybackStream: any = INITIAL_DATA.obsWebrtcPlaybackStream;
  excludedDevices: BroadcastWebRTCExtendedMediaDeviceInfo[] =
    INITIAL_DATA.excludedDevices; //this is only used for QA purposes to test different errors
  cameras: BroadcastWebRTCExtendedMediaDeviceInfo[] = INITIAL_DATA.cameras;
  microphones: BroadcastWebRTCExtendedMediaDeviceInfo[] =
    INITIAL_DATA.microphones;
  isChangingCameraDevice: boolean = INITIAL_DATA.isChangingCameraDevice;
  selectedCameraDeviceId: string | null = INITIAL_DATA.selectedCameraDeviceId;
  selectedMicrophoneDeviceId: string | null =
    INITIAL_DATA.selectedMicrophoneDeviceId;
  selectedResolution: string = INITIAL_DATA.selectedResolution;
  videoQuality: number = INITIAL_DATA.videoQuality;
  videoBitrate: number = INITIAL_DATA.videoBitrate;
  isMicrophoneMuted: boolean = INITIAL_DATA.isMicrophoneMuted;
  volumeGain: GainNode | null = INITIAL_DATA.volumeGain;
  availableResolutions: BroadcastVideoResolutionOption[] =
    INITIAL_DATA.availableResolutions;
  isWaitingForWebRTCConnection: boolean =
    INITIAL_DATA.isWaitingForWebRTCConnection;
  obsStreamConnected: boolean = INITIAL_DATA.obsStreamConnected;
  obsTimer: number = INITIAL_DATA.obsTimer;
  videoQualityMarker: number = INITIAL_DATA.videoQualityMarker;
  obsTimerInterval: any = INITIAL_DATA.obsTimerInterval;
  isStreamInitialized: boolean = INITIAL_DATA.isStreamInitialized;
  isGatheringMediaDeviceData: boolean = INITIAL_DATA.isGatheringMediaDeviceData;
  isConnectingToWebrtcStream: boolean = INITIAL_DATA.isConnectingToWebrtcStream;
  hasUserDeniedPermissionsToDevices: boolean =
    INITIAL_DATA.hasUserDeniedPermissionsToDevices;
  broadcastError: BroadcastError = INITIAL_DATA.broadcastError;
  broadcastErrorAction: BroadcastErrorAction =
    INITIAL_DATA.broadcastErrorAction;
  publishToken: string = INITIAL_DATA.publishToken;
  publishTokenFetchError: boolean = INITIAL_DATA.publishTokenFetchError;
  webrtcStreamSettings: WowzaWebRTCStreamSettings =
    INITIAL_DATA.webrtcStreamSettings;

  peerConnection: RTCPeerConnection | null = INITIAL_DATA.peerConnection;
  peerConnectionConfig: BroadcastWebRTCPeerConnectionConfig =
    INITIAL_DATA.peerConnectionConfig;
  audioSender: RTCRtpSender | null = INITIAL_DATA.audioSender;
  videoSender: RTCRtpSender | null = INITIAL_DATA.videoSender;
  websocketConnection: WebSocket | null = INITIAL_DATA.websocketConnection;
  receivedSDPResponse: boolean = INITIAL_DATA.receivedSDPResponse;
  peerConnectionStateConnected: boolean =
    INITIAL_DATA.peerConnectionStateConnected;
  peerConnectionStateConnectedReaction: IReactionDisposer | undefined =
    INITIAL_DATA.peerConnectionStateConnectedReaction;
  obsVideoSettings: BroadcastOBSVideoSettings | null =
    INITIAL_DATA.obsVideoSettings;
  lastStoppedAt: number | undefined = INITIAL_DATA.lastStoppedAt;
  isFetchingPublishToken: boolean = INITIAL_DATA.isFetchingPublishToken;
  private retryingWebRTC: boolean = false;
  private retryingWebRTCCount: number = 0;

  resetStore = async (stopAction?: "logout" | "offline") => {
    if (broadcastStore.streamState === BroadcastStreamState.started) {
      await broadcastStore.stopStreaming();
    }
    Object.assign(this, {
      ...INITIAL_DATA,
      broadcastError:
        stopAction === "logout"
          ? INITIAL_DATA.broadcastError
          : this.broadcastError, //do not overwrite the error,
      broadcastErrorAction:
        stopAction === "logout"
          ? INITIAL_DATA.broadcastErrorAction
          : this.broadcastErrorAction, //do not overwrite the error function
      lastStoppedAt:
        stopAction === "logout"
          ? INITIAL_DATA.lastStoppedAt
          : this.lastStoppedAt, //do not overwrite the last stopped at
      obsStreamConnected:
        stopAction === "logout"
          ? INITIAL_DATA.obsStreamConnected
          : this.obsStreamConnected, //do not overwrite the obsStreamConnected
    });
  };

  constructor() {
    makeAutoObservable(this);
  }

  log = (shouldLogToGraylog: boolean = true, ...params: any[]) => {
    logger.log(logPrefix, ...params);
    if (shouldLogToGraylog) {
      logToGraylog(logPrefix, "log received params", params);
    }
  };

  get isPeerConnectionStarted() {
    return this.peerConnection !== null;
  }

  setAudioSender = (sender: RTCRtpSender | null) => {
    this.log(false, "setAudioSender started");
    this.audioSender = sender;
    this.log(false, "setAudioSender finished");
  };

  setPeerConnection = (connection: RTCPeerConnection | null) => {
    this.log(false, "setPeerConnection started");
    this.peerConnection = connection;
    this.log(false, "setPeerConnection finished");
  };

  setWebsocketConnection = (connection: WebSocket | null) => {
    this.websocketConnection = connection;
  };

  setVideoSender = (sender: RTCRtpSender | null) => {
    this.log(false, "setVideoSender started");
    this.videoSender = sender;
    this.log(false, "setVideoSender finished");
  };

  setBroadcastError = (
    error: BroadcastError,
    action: BroadcastErrorAction = null
  ) => {
    this.log(false, "setBroadcastError started");
    this.broadcastErrorAction = action;
    this.broadcastError = error;
    this.log(false, "setBroadcastError finished");
  };

  setStreamType = async (type: BroadcastStreamType | null) => {
    this.log(false, "setStreamType started", type);
    this.streamType = type;

    if (type === BroadcastStreamType.WEBRTC) {
      try {
        await this.initMediaDevicesAndInitWebrtcStream();
      } catch (error) {}
    } else if (type === BroadcastStreamType.OBS) {
    }
    this.log(false, "setStreamType finished");
  };

  init = async () => {
    this.log(false, "init started");
    Object.assign(this, {
      ...INITIAL_DATA,
      broadcastError: this.broadcastError,
      lastStoppedAt: this.lastStoppedAt,
      obsStreamConnected: this.obsStreamConnected,
    });
    broadcastStreamService.initializeWindowScope();
    this.isGatheringMediaDeviceData = true;
    this.isConnectingToWebrtcStream = true;
    this.hasUserDeniedPermissionsToDevices = false;

    if (this.obsStreamConnected) {
      this.streamType = BroadcastStreamType.OBS;
    }

    await this.getPublishToken(true);
    this.addPeerConnectionStateConnectedReaction();
    this.log(false, "init finished");
  };

  addPeerConnectionStateConnectedReaction = () => {
    this.removePeerConnectionStateConnectedReaction();
    this.peerConnectionStateConnectedReaction = reaction(
      () => this.peerConnectionStateConnected,
      async () => {
        if (
          this.peerConnectionStateConnected &&
          broadcastStore.streamState !== BroadcastStreamState.started
        ) {
          await broadcastStore.startShowType();
          this.startedWebRTCBroadcasting = true;
        }
      }
    );
  };

  removePeerConnectionStateConnectedReaction = () => {
    if (this.peerConnectionStateConnectedReaction) {
      this.peerConnectionStateConnectedReaction();
      this.peerConnectionStateConnectedReaction = undefined;
    }
  };

  getPublishToken = async (hideError?: boolean) => {
    this.log(false, "getPublishToken started");
    try {
      const profile = profileStore.modelProfile;
      const channelName = (profile?.screen_name || "").toLowerCase();
      this.isFetchingPublishToken = true;
      const token = await broadcastStreamService.getPublishToken(
        channelName,
        profile?.id,
        hideError
      );
      if (token !== null) {
        this.publishToken = token;
        this.publishTokenFetchError = false;
        this.log(false, "getPublishToken success");
      } else {
        nodeChatStore.closeChatSocket();
        throw new Error("Failed to get publish token after 3 retries");
      }
    } catch (error) {
      this.log(true, "getPublishToken failed");
      this.publishTokenFetchError = true;
    } finally {
      this.log(false, "getPublishToken finished");
      this.isFetchingPublishToken = false;
    }
  };

  setNanoPlayerAndSettings = (
    player: CamsNanoPlayer | null,
    settings: NanoPlayerSettings | null
  ) => {
    this.log(false, "setNanoPlayerAndSettings started");
    this.nanoPlayer = player;
    this.nanoPlayerSettings = settings;
    this.log(false, "setNanoPlayerAndSettings finished");
  };

  setIsObsConnected = () => {
    this.log(false, "setIsObsConnected started");
    this.obsStreamConnected = true;
    this.streamType = BroadcastStreamType.OBS;
    this.log(false, "setIsObsConnected finished");
  };

  stopOBSTimer = () => {
    this.log(false, "stopOBSTimer started");
    this.obsTimer = 0;
    if (!!this.obsTimerInterval) {
      clearInterval(this.obsTimerInterval);
    }
    this.log(false, "stopOBSTimer finished");
  };

  startOBSTimer = () => {
    this.log(false, "startOBSTimer started");
    this.stopOBSTimer();
    this.obsTimerInterval = setInterval(() => {
      this.obsTimer += 1;
    }, 1000);
    this.log(false, "startOBSTimer finished");
  };

  onWebRTCPlayStream = event => {
    this.log(false, "onWebRTCPlayStream started");
    this.mediaStream = event.streams[0];
    this.log(false, "onWebRTCPlayStream finished");
  };

  get obsBroadcastKey(): string {
    const profile = profileStore.modelProfile;
    const broadcastKey = (profile?.screen_name || "").toLowerCase();
    this.log(false, "obsBroadcastKey returning", broadcastKey);
    return broadcastKey;
  }

  get hasPublishToken(): boolean {
    return !!broadcastStreamStore.publishToken;
  }

  get obsBroadcastUrl(): string {
    const channelName = (
      profileStore.modelProfile?.screen_name || ""
    ).toLowerCase();
    const url = `${config.broadcastRtmp}/${config.broadcastApp}/${channelName}?token=${broadcastStreamStore.publishToken}&source=OBS`;
    this.log(false, "obsBroadcastUrl returning", url);
    return url;
  }

  get isStreamingWebRTC(): boolean {
    const _isStreamingWebRTC = this.streamType === BroadcastStreamType.WEBRTC;
    this.log(false, "isStreamingWebRTC returning", _isStreamingWebRTC);
    return _isStreamingWebRTC;
  }

  get isStreamingOBS(): boolean {
    const _isStreamingOBS = this.streamType === BroadcastStreamType.OBS;
    this.log(false, "isStreamingOBS returning", _isStreamingOBS);

    return _isStreamingOBS;
  }

  setSelectedMicrophoneDevice = async (microphoneId: string) => {
    this.log(false, "setSelectedMicrophoneDevice started");
    this.setSelectedMicrophoneDeviceId(microphoneId);
    const stream = await broadcastStreamService.setSelectedMicrophoneDevice(
      this.peerConnection,
      this.audioSender,
      this.videoSender,
      this.mediaStream,
      microphoneId
    );
    this.setMediaStream(stream);
    this.log(false, "MicrophoneDevice finished");
  };

  setSelectedResolution = async (resolution: string) => {
    this.log(false, "setSelectedResolution started");
    this.selectedResolution = resolution;
    const { width, height } = parseResolutionString(resolution);
    const mediaStream =
      await broadcastStreamService.applyMediaStreamConstraints(
        this.mediaStream,
        {
          video: {
            advanced: [
              {
                width: {
                  exact: width,
                },
                height: {
                  exact: height,
                },
              },
            ],
          },
        }
      );
    this.setMediaStream(mediaStream);
    this.log(false, "setSelectedResolution finished");
  };

  setSelectedCameraDeviceId = (deviceId: string) => {
    this.log(false, "setSelectedCameraDeviceId started", deviceId);
    this.selectedCameraDeviceId = deviceId;
    this.log(false, "setSelectedCameraDeviceId finished");
  };

  setSelectedMicrophoneDeviceId = (deviceId: string) => {
    this.log(false, "setSelectedMicrophoneDeviceId started");
    this.selectedMicrophoneDeviceId = deviceId;
    this.log(false, "setSelectedMicrophoneDeviceId finished");
  };

  setExcludedDevices = (devices: BroadcastWebRTCExtendedMediaDeviceInfo[]) => {
    this.log(false, "setExcludedDevices started", devices);
    this.excludedDevices = devices;
    this.log(false, "setExcludedDevices finished");
  };

  setCameras = (cameras: BroadcastWebRTCExtendedMediaDeviceInfo[]) => {
    this.log(false, "setCameras started", cameras);
    this.cameras = cameras;
    this.log(false, "setCameras finished");
  };

  setMicrophones = (microphones: BroadcastWebRTCExtendedMediaDeviceInfo[]) => {
    this.log(false, "setMicrophones started");
    this.microphones = microphones;
    this.log(false, "setMicrophones finished");
  };

  get availableResolutionsForSelectedCamera() {
    const resolutions = this.availableResolutions.filter(
      resolution =>
        this.selectedCameraDeviceId &&
        resolution.deviceIds?.includes(this.selectedCameraDeviceId)
    );
    this.log(
      true,
      "availableResolutionsForSelectedCamera returning",
      resolutions
    );
    return resolutions;
  }

  setIsChangingCamera = (isChanging: boolean) => {
    this.log(false, "setIsChangingCamera started");
    this.isChangingCameraDevice = isChanging;
    this.log(false, "setIsChangingCamera finished");
  };

  setSelectedCameraDevice = async (cameraId: string) => {
    this.log(false, "setSelectedCameraDevice started");
    this.setIsChangingCamera(true);
    this.setSelectedCameraDeviceId(cameraId);
    const mediaStream = await broadcastStreamService.setSelectedCameraDevice(
      this.peerConnection,
      this.audioSender,
      this.videoSender,
      this.mediaStream,
      cameraId
    );
    this.setMediaStream(mediaStream);
    const maxResolutionDeviceSupports =
      broadcastStreamService.getMaxResolutionCameraDeviceSupports(
        this.availableResolutionsForSelectedCamera
      );
    await this.setSelectedResolution(maxResolutionDeviceSupports);
    this.setIsChangingCamera(false);
    this.log(false, "setSelectedCameraDevice finished");
  };

  setIsMicrophoneMuted = (isMuted: boolean) => {
    this.isMicrophoneMuted = isMuted;
    broadcastStreamService.setMediaStreamTrackEnabled(
      this.mediaStream,
      "audio",
      !isMuted
    );
  };

  setMediaStream = (stream: MediaStream | null) => {
    this.log(false, "setMediaStream started");
    this.mediaStream = stream;
    this.log(false, "setMediaStream finished");
  };

  initMediaDevicesAndInitWebrtcStream = async () => {
    this.log(false, "initMediaDevicesAndInitWebrtcStream started");
    try {
      this.hasUserDeniedPermissionsToDevices = false;
      this.isGatheringMediaDeviceData = true;
      // do this to get permissions for all devices then kill stream, otherwise since a webcam will be active
      // resolution checks will fail to provide accurate results
      // initiate the selected webcam at the end of this try block after all setup has been completed
      let mediaStream: MediaStream | null =
        await broadcastStreamService.getMediaStreamWithAllMediaDevices();
      broadcastStreamService.stopMediaStreamTracks(mediaStream);
      let resolutions,
        maxResolution,
        cameras,
        microphones,
        selectedMicrophoneDeviceId,
        selectedCameraDeviceId;
      try {
        const result = await broadcastStreamService.gatherMediaDeviceData(
          this.excludedDevices
        );
        resolutions = result.resolutions;
        maxResolution = result.maxResolution;
        cameras = result.cameras;
        microphones = result.microphones;
        selectedCameraDeviceId = result.selectedCameraDeviceId;
        broadcastStreamService.setLastUsedCameraDeviceIdToCookies(
          result.selectedCameraDeviceId
        );
        selectedMicrophoneDeviceId = result.selectedMicrophoneDeviceId;
        broadcastStreamService.setLastUsedMicrophoneDeviceToCookies(
          result.selectedMicrophoneDeviceId
        );
      } catch (error) {
        this.log(
          true,
          "initMediaDevicesAndInitWebrtcStream gatherMediaDeviceData failed",
          error
        );
      }

      if (!cameras.length || cameras.every(camera => !camera.canBeUsed)) {
        throw new Error(BROADCAST_NO_WEBCAMS_AVAILABLE_ERROR);
      } else if (!microphones.length) {
        throw new Error(BROADCAST_NO_MICROPHONES_AVAILABLE_ERROR);
      } else if (!resolutions || resolutions.length === 0) {
        throw new Error(BROADCAST_NO_WEBCAMS_AVAILABLE_ERROR);
      }
      // camera and microphone set should come before setAvailableResolutions
      this.setCameras(cameras);
      this.setMicrophones(microphones);
      this.setAvailableResolutions(resolutions);
      await this.setSelectedResolution(maxResolution);
      this.setSelectedCameraDeviceId(selectedCameraDeviceId);
      this.setSelectedMicrophoneDeviceId(selectedMicrophoneDeviceId);
      const screenName = profileStore.modelProfile?.username || "";
      const channelName = screenName.toLowerCase();
      if (!this.publishToken) {
        await this.getPublishToken();
        if (this.publishTokenFetchError) {
          return this.stopWebRTCBroadcast();
        }
      }
      this.setWebrtcStreamSettings({
        streamInfo: {
          ...WOWZA_DEFAULT_WEBRTC_SETTINGS.streamInfo,
          applicationName: `${config.webrtcApp}/${channelName}`,
          streamName: `${channelName}?token=${this.publishToken}`,
        },
      });
      const { width, height } = parseResolutionString(maxResolution);
      mediaStream = await broadcastStreamService.getUserMedia({
        audio: true,
        video: {
          deviceId: { exact: selectedCameraDeviceId },
          width: { exact: width },
          height: { exact: height },
        },
      });
      if (this.streamType === BroadcastStreamType.WEBRTC) {
        this.setMediaStream(mediaStream);
        this.isConnectingToWebrtcStream = true;
        if (this.isMicrophoneMuted) {
          this.log(
            false,
            "initMediaDevicesAndInitWebrtcStream microphoneMuted"
          );
          broadcastStreamService.setMediaStreamTrackEnabled(
            this.mediaStream,
            "audio",
            false
          );
        }
        this.log(false, "initMediaDevicesAndInitWebrtcStream success");
      } else {
        this.log(
          true,
          "initMediaDevicesAndInitWebrtcStream skipped as user changed to OBS while this function was already running"
        );
        broadcastStreamService.stopMediaStreamTracks(mediaStream);
      }
    } catch (error) {
      this.log(true, "initMediaDevicesAndInitWebrtcStream failed", error);
      this.resetStore();
      if (error.message?.includes(BROADCAST_NO_WEBCAMS_AVAILABLE_ERROR)) {
        this.setBroadcastError({
          id: "broadcast-error.no-cameras-available",
          defaultMessage:
            "The minimum requirement to broadcast is 720p resolution. Please make sure you have a 720p capable webcam and you have selected it.",
        });
      } else if (
        error.message?.includes(BROADCAST_NO_MICROPHONES_AVAILABLE_ERROR)
      ) {
        this.setBroadcastError({
          id: "broadcast-error.no-microphones-available",
          defaultMessage:
            "You need at least 1 microphone to be able to stream.",
        });
      } else if (error.message?.includes("Could not start video source")) {
        this.setBroadcastError({
          id: "broadcast-error.webrtc-device-in-use",
          defaultMessage:
            "Your webcam and/or microphone might be currently in use by another application. Please quit any application that is accessing your webcam and/or microphone and try again.",
        });
      } else if (error.message?.includes("Permission denied")) {
        this.hasUserDeniedPermissionsToDevices = true;
        this.setBroadcastError({
          id: "broadcast-error.webrtc-permission-denied",
          defaultMessage:
            "We can't access your webcam because you have denied permissions. Please allow browser to access your webcam and microphone.",
        });
      } else {
        this.setBroadcastError({
          id: "broadcast-error.general-error",
          defaultMessage:
            "There seems to be something wrong. Please try again.",
        });
      }
    } finally {
      this.isGatheringMediaDeviceData = false;
      this.isConnectingToWebrtcStream = false;
      this.log(false, "initMediaDevicesAndInitWebrtcStream finished");
    }
  };

  initObsBroadcastAndInitWebrtcStream = async () => {
    try {
      this.log(false, "initObsBroadcastAndInitWebrtcStream started");
      this.isConnectingToWebrtcStream = true;
      const { streamName, applicationName, adminName, profileId } =
        this.streamCredentials;
      const edgeLBPath = await broadcastStreamService.getEdgeLBPath();
      const playStreamSettings: Partial<WowzaWebRTCStreamSettings> = {
        streamInfo: {
          applicationName,
          streamName,
        },
      };

      let adminResponse;
      try {
        adminResponse = await broadcastStreamService.getAdminCredentials(
          adminName,
          streamName,
          profileId
        );
      } catch (error) {
        this.log(
          true,
          "initObsBroadcastAndInitWebrtcStream failed on getAdminCredentials",
          error
        );
      }

      this.setWebrtcStreamSettings(playStreamSettings);

      const settings: NanoPlayerSettings = {
        token: adminResponse?.token,
        origin: adminResponse?.origin,
        applicationName: adminResponse?.applicationName || applicationName,
        edgeLBPath,
        streamName,
      };
      const player = await broadcastStreamService.initializeNanoAndPlayStream(
        NANOPLAYER_ELEMENT_ID,
        settings
      );
      this.setNanoPlayerAndSettings(player, settings);
      this.isStreamInitialized = true;
    } catch (error) {
      this.log(true, "initObsBroadcastAndInitWebrtcStream failed", error);
    } finally {
      this.isConnectingToWebrtcStream = false;
      this.log(false, "initObsBroadcastAndInitWebrtcStream finished");
    }
  };

  get streamCredentials(): BroadcastStreamCredentials {
    const profile = profileStore.modelProfile;
    const profileId = profile?.id;
    const streamName = (profile?.screen_name || "").toLowerCase();
    const credentials = {
      applicationName: `${config.webrtcApp}/${streamName}`,
      channelName: `${streamName}?token=${this.publishToken}`,
      streamName,
      adminName: `${streamName}%2bviewmyself`,
      profileId,
    };
    this.log(false, "streamCredentials returning", credentials);
    return credentials;
  }

  getCam2camStreamCredentials = async (
    memberName: string,
    retries: number = 0
  ): Promise<BroadcastStreamViewerCredentials> => {
    const profile = profileStore.modelProfile;
    const profileId = profile?.id;
    const channelName = `${memberName}+${profile?.screen_name}`.toLowerCase();
    const streamName = memberName?.toLowerCase();
    const edgeLBPath = await broadcastStreamService.getEdgeLBPath();
    const data = await broadcastStreamService.getViewerCredentials(
      profile.screen_name,
      channelName,
      profileId
    );
    if (!data?.token || !data?.origin || !data.applicationName) {
      if (retries < 5) {
        await timeoutPromise(2000 * (retries + 1));
        return this.getCam2camStreamCredentials(memberName, retries + 1);
      }
    }
    const credentials = {
      token: data?.token || "",
      origin: data?.origin || "",
      applicationName: data?.applicationName || "",
      streamName,
      channelName,
      edgeLBPath,
      isCam2Cam: true,
    };
    this.log(false, "getCam2camStreamCredentials returning", credentials);
    return credentials;
  };

  initializeCam2CamNanoPlayStream = async (
    hostId: string,
    memberName: string
  ): Promise<CamsNanoPlayer> => {
    this.log(false, "initializeCam2CamNanoPlayStream started", hostId);
    const {
      streamName,
      channelName,
      token,
      origin,
      applicationName,
      edgeLBPath,
      isCam2Cam,
    } = await this.getCam2camStreamCredentials(memberName);
    const settings: NanoPlayerSettings = {
      token: token,
      origin: origin,
      applicationName: applicationName,
      edgeLBPath,
      channelName,
      streamName,
      isCam2Cam,
    };
    const player = await broadcastStreamService.initializeNanoAndPlayStream(
      hostId,
      settings
    );
    this.log(
      true,
      "initializeCam2CamNanoPlayStream finished, returning player"
    );
    return player;
  };

  setWebrtcStreamSettings = (settings: Partial<WowzaWebRTCStreamSettings>) => {
    this.log(false, "setWebrtcStreamSettings started");
    this.webrtcStreamSettings = {
      ...WOWZA_DEFAULT_WEBRTC_SETTINGS,
      ...settings,
    };
    this.log(false, "setWebrtcStreamSettings finished");
  };

  handleStreamStatsReceived = (data: BroadcastStreamStats): void => {
    this.log(false, "handleStreamStatsReceived started", data);
    this.videoQualityMarker = data.qualityMark;
    this.log(false, "handleStreamStatsReceived finished");
  };

  validateStreamStats = async (streamStats: BroadcastStreamStat[]) => {
    const originalStreamStats = streamStats.find(
      s =>
        s.name.toLowerCase() ===
        profileStore.modelProfile.screen_name.toLowerCase()
    );
    this.log(
      true,
      "validateStreamStats started",
      streamStats,
      profileStore.modelProfile.screen_name.toLowerCase()
    );
    if (originalStreamStats && this.streamType === BroadcastStreamType.OBS) {
      const videoBitRate = originalStreamStats.messagesInBytesRate;
      this.log(false, "validateStreamStats received bitrate", videoBitRate);
      const {
        videoHeight,
        videoWidth,
        frameRate: videoFrameRate,
      } = originalStreamStats.videoInfo;
      this.log(
        true,
        "validateStreamStats received videoHeight, videoWidth and frameRate",
        videoHeight,
        videoWidth,
        videoFrameRate
      );

      const settings: BroadcastOBSVideoSettings = {
        resolution: `${videoWidth}x${videoHeight}`,
        frameRate: videoFrameRate,
        bitRate: videoBitRate,
      };
      const validatedSettings =
        broadcastStreamService.getOBSSettingsValidation(settings);

      const areSettingsValid =
        broadcastStreamService.getAreAllOBSSettingsValid(validatedSettings);

      if (!areSettingsValid) {
        modalStore.openSecondaryModal(
          <BroadcastInvalidObsSettingsError
            obsVideoSettings={settings}
            obsSettingsValidation={validatedSettings}
          />,
          {
            name: BROADCAST_MODAL_OBS_INVALID_SETTINGS_NAME,
          }
        );
        await broadcastStrategy.disconnect();
        // do this after disconnect as disconnect overwrites the settings
        this.setOBSVideoSettings(settings);
      }

      return areSettingsValid;
    }
  };

  setOBSVideoSettings = (settings: BroadcastOBSVideoSettings) => {
    this.log(false, "setOBSVideoSettings started");
    this.obsVideoSettings = settings;
    this.log(false, "setOBSVideoSettings finished");
  };

  get obsSettingsValidation(): BroadcastOBSVideoSettingsValidation {
    return broadcastStreamService.getOBSSettingsValidation(
      this.obsVideoSettings
    );
  }

  get areAllObsSettingsValid(): boolean {
    return broadcastStreamService.getAreAllOBSSettingsValid(
      this.obsSettingsValidation
    );
  }

  clearErrors = () => {
    this.log(false, "clearErrors started");
    this.setBroadcastError(null);
    this.log(false, "clearErrors finished");
  };

  startWebRTCBroadcast = async () => {
    this.log(false, "startWebRTCBroadcast started");
    if (!!this.mediaStream && !this.startedWebRTCBroadcasting) {
      broadcastStore.setStreamState(BroadcastStreamState.startingStream);
      this.receivedSDPResponse = false;
      this.peerConnectionStateConnected = false;
      const websocket =
        await broadcastStreamService.createWebsocketInstanceForWebRTC(
          `${this.webrtcStreamSettings.sdpURL}/webrtc-session.json`
        );
      const websocketWithListeners =
        await broadcastStreamService.addWebsocketListeners(
          websocket,
          this.websocketListenersForWebRTC
        );
      if (!websocketWithListeners) {
        broadcastStore.setStreamState(BroadcastStreamState.stopped);
      } else {
        this.setWebsocketConnection(websocketWithListeners);
      }
    }
    this.log(false, "startWebRTCBroadcast finished");
  };

  stopNanoPlayer = async () => {
    await this.nanoPlayer?.stop();
  };

  stopOBSBroadcast = async () => {
    this.stopOBSTimer();
    await this.onBeforeStreamStop();
  };

  stopWebRTCBroadcast = async () => {
    this.log(false, "stopWebRTCBroadcast started");
    await this.stopSocketConnections();
    await broadcastStreamService.stopCamerasAndMicrophones(
      this.cameras,
      this.microphones
    );
    broadcastStreamService.stopMediaStreamTracks(this.mediaStream);
    await this.onBeforeStreamStop();
    this.setMediaStream(null);
    this.setAudioSender(null);
    this.setVideoSender(null);
    this.lastStoppedAt = new Date().getTime();
    this.startedWebRTCBroadcasting = false;
    broadcastStore.setStreamState(BroadcastStreamState.stopped);
    this.log(false, "stopWebRTCBroadcast finished");
    this.retryingWebRTC = false;
  };

  get isJustStopped() {
    return (
      this.lastStoppedAt &&
      new Date().getTime() - this.lastStoppedAt < JUST_STOPPED_INTERVAL
    );
  }

  onBeforeStreamStop = async () => {
    await this.stopNanoPlayer();
    await this.setNanoPlayerAndSettings(null, null);
    await this.setStreamType(null);
  };

  stopSocketConnections = async () => {
    await broadcastStreamService.closeWebsocketConnection(
      this.websocketConnection
    );
    this.setWebsocketConnection(null);
    await broadcastStreamService.closePeerConnection(this.peerConnection);
    this.setPeerConnection(null);
  };

  setVideoQuality = async (value: number) => {
    this.log(false, "setVideoQuality started");
    this.videoQuality = value;
    this.videoBitrate = Math.floor((value * WOWZA_MAX_VIDEO_BITRATE) / 100);
    this.log(false, "setVideoQuality finished");
  };

  setAvailableResolutions = (resolutions: BroadcastVideoResolutionOption[]) => {
    this.log(false, "setAvailableResolutions started", resolutions);
    this.availableResolutions = resolutions;
    this.log(false, "setAvailableResolutions finished");
  };

  onWebRTCError = async error => {
    this.log(false, "onWebRTCError started", error);
    this.log(true, "onWebRTCError is retrying");
    await this.retryEstablishingWebRTCConnection();
    this.log(false, "onWebRTCError finished");
  };

  onConnectionStateChangePeerConnection = async (
    evt,
    _peerConnection: RTCPeerConnection
  ) => {
    const connectionState = evt?.target?.connectionState;
    this.log(
      true,
      "onConnectionStateChangePeerConnection started",
      connectionState
    );
    if (connectionState) {
      if (connectionState === "failed") {
        this.log(
          true,
          "onConnectionStateChangePeerConnection found failed connection, retrying"
        );
        await this.retryEstablishingWebRTCConnection();
      } else if (
        connectionState === "disconnected" ||
        connectionState === "closed"
      ) {
        if (
          connectionState === "disconnected" &&
          broadcastStore.streamState !== BroadcastStreamState.stoppingStream
        ) {
          this.log(
            true,
            "onConnectionStateChangePeerConnection found disconnected connection but it was not initiated by the user, retrying"
          );
          await this.retryEstablishingWebRTCConnection();
        } else {
          this.log(
            true,
            "onConnectionStateChangePeerConnection found disconnected or closed connection but it was initiated by the user, disconnecting broadcast"
          );
          await broadcastStrategy.disconnect();
        }
        this.peerConnectionStateConnected = false;
      } else if (connectionState === "connected") {
        this.peerConnectionStateConnected = true;
      }
    }
    this.log(false, "onConnectionStateChangePeerConnection finished");
  };

  get websocketListenersForWebRTC(): BroadcastWebRTCWebsocketListeners {
    return {
      message: this.onMessageWebsocketForWebRTC,
      error: this.onErrorWebsocketForWebRTC,
      open: this.onOpenWebsocketForWebRTC,
      close: this.onCloseWebsocketForWebRTC,
      stats: this.onStatsWebsocketForWebRTC,
    };
  }

  onCloseWebsocketForWebRTC = async () => {
    this.log(false, "onCloseWebsocketForWebRTC started");
    this.setWebsocketConnection(null);
  };

  onOpenWebsocketForWebRTC = () => {
    this.log(false, "onOpenWebsocketForWebRTC started");
    const peerConnection =
      broadcastStreamService.createPeerConnectionInstanceForWebRTC(
        this.peerConnectionConfig
      );
    const peerConnectionWithListeners =
      broadcastStreamService.addPeerConnectionListeners(
        peerConnection,
        this.getPeerConnectionListenersForWebRTC(peerConnection)
      );
    const { audioSender, videoSender } =
      broadcastStreamService.getAudioAndVideoSenderFromPeerConnection(
        peerConnectionWithListeners,
        this.mediaStream
      );
    this.log(
      true,
      "onOpenWebsocketForWebRTC received audioSender and videoSender",
      audioSender,
      videoSender
    );
    this.setPeerConnection(peerConnectionWithListeners);
    this.setAudioSender(audioSender);
    this.setVideoSender(videoSender);
    this.log(false, "onOpenWebsocketForWebRTC finished");
  };

  onMessageWebsocketForWebRTC = async event => {
    const json = JSON.parse(event.data);
    this.log(
      false,
      "onMessageWebsocketForWebRTC started with event data",
      json
    );

    const status = Number(json["status"]);
    // 503 is for when stream has already started, do not stop if it is 503
    if (status !== 200 && status !== 503) {
      this.log(
        true,
        "onMessageWebsocketForWebRTC failed, statusDescription:",
        json["statusDescription"],
        status
      );
      await this.stopWebRTCBroadcast();
    } else {
      try {
        const sdpData =
          broadcastStreamService.getSDPDataFromWebsocketResponse(json);
        if (sdpData) {
          this.log(
            true,
            "onMessageWebsocketForWebRTC got sdpData, proceeding to getting peerConnection"
          );
          this.receivedSDPResponse = true;
          const peerConnection =
            await broadcastStreamService.setPeerConnectionRemoteDescription(
              this.peerConnection,
              sdpData
            );
          if (peerConnection) {
            this.log(
              true,
              "onMessageWebsocketForWebRTC got peerConnection, proceeding to getting iceCandidates"
            );
            const iceCandidates =
              broadcastStreamService.getIceCandidatesFromWebsocketResponse(
                json
              );
            if (iceCandidates) {
              this.log(
                true,
                "onMessageWebsocketForWebRTC got iceCandidates, proceeding to starting show"
              );
              const peerConnectionWithIceCandidates =
                await broadcastStreamService.addIceCandidatesToPeerConnection(
                  peerConnection,
                  iceCandidates
                );
              this.setPeerConnection(peerConnectionWithIceCandidates);
            } else {
              this.log(
                true,
                "onMessageWebsocketForWebRTC failed, did not get iceCandidates"
              );
            }
          } else {
            this.log(
              true,
              "onMessageWebsocketForWebRTC failed, did not get peerConnection"
            );
          }
        } else {
          this.log(
            true,
            "onMessageWebsocketForWebRTC failed, did not get sdpData, stopping WebRTC broadcast"
          );

          this.setBroadcastError({
            id: "broadcast-error.general-error",
            defaultMessage:
              "There seems to be something wrong. Please try again.",
          });

          await this.stopWebRTCBroadcast();
        }
      } catch (error) {
        this.log(true, "onMessageWebsocketForWebRTC failed", error);
      } finally {
        this.log(false, "onMessageWebsocketForWebRTC finished");
        if (status === 200) {
          this.retryingWebRTC = false;
          this.retryingWebRTCCount = 0;
        }
      }
    }
  };

  retryEstablishingWebRTCConnection = async () => {
    // sometimes websocket BE does not send ice candidates for some reason
    // do a retry mechanism
    this.log(false, "retryEstablishingWebRTCConnection started");
    await this.onCloseWebsocketForWebRTC();
    await this.startWebRTCBroadcast();
    this.log(false, "retryEstablishingWebRTCConnection finished");
  };

  getPeerConnectionListenersForWebRTC(
    peerConnection: RTCPeerConnection
  ): BroadcastPeerConnectionListeners {
    return {
      connectionstatechange: event =>
        this.onConnectionStateChangePeerConnection(event, peerConnection),
      negotiationneeded: this.onNegotiationNeededPeerConnection,
      icecandidate: this.onIceCandidatePeerConnection,
    };
  }

  onNegotiationNeededPeerConnection = async () => {
    try {
      this.log(false, "onNegotiationNeeded started");
      await broadcastStreamService.createPeerNegotiationOffer(
        this.peerConnection,
        this.websocketConnection,
        this.mungeData,
        this.webrtcStreamSettings
      );

      setTimeout(async () => {
        if (!this.receivedSDPResponse) {
          this.log(
            true,
            "onNegotiationNeeded succeeded but SDP data was not received, retrying"
          );
          await this.retryEstablishingWebRTCConnection();
        }
      }, 3000);
    } catch (error) {
      this.log(true, "onNegotiationNeeded failed", error);
    } finally {
      this.log(false, "onNegotiationNeeded finished");
    }
  };

  get mungeData() {
    const _mungeData = WOWZA_DEFAULT_WEBRTC_SETTINGS.mediaInfo;
    return _mungeData;
  }

  onIceCandidatePeerConnection = event => {
    if (event.candidate != null) {
      this.log(
        true,
        "onIceCandidatePeerConnection received" +
          JSON.stringify({ ice: event.candidate })
      );
    }
  };

  onStatsWebsocketForWebRTC = stats => {
    this.log(false, "onStatsWebsocketForWebRTC received", stats);
  };

  retryingToConecctWebRTC = () => {
    this.log(false, "retrying WebsocketForWebRTC");
    setTimeout(() => this.setStreamType(BroadcastStreamType.WEBRTC), 500);
    setTimeout(() => this.startWebRTCBroadcast(), 1000);
    this.log(false, "retrying WebsocketForWebRTC finished");
    if (!this.retryingWebRTC || this.retryingWebRTCCount >= 5) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.error.somethingWentWrong",
          default: "Something went wrong. Please try again!",
        },
        variant: SnackbarVariants.ERROR,
      });
      this.retryingWebRTCCount = 0;
      this.retryingWebRTC = false;
      this.stopWebRTCBroadcast();
    }
  };

  onErrorWebsocketForWebRTC = (error, shouldRetry = true) => {
    if (shouldRetry) {
      this.retryingWebRTC = true;
    }
    this.log(true, "onErrorWebsocketForWebRTC started", error);
    if (error.message == null) {
      if (error.target != null) {
        this.log(
          true,
          "onErrorWebsocketForWebRTC typeof error.target: " +
            typeof error.target
        );
      }
    }
    this.log(false, "onErrorWebsocketForWebRTC finished");
    if (this.retryingWebRTC && shouldRetry) {
      this.retryingWebRTCCount++;
      this.retryingToConecctWebRTC();
    }
  };
}
