import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import TailwindSwitch from "library/components/_tailwind/switch";
import CookieStorage from "library/core/utility/cookie-storage";

type Props = {};

const UpcomingFeatures: React.ComponentType<Props> = ({}) => {
  const [bulkMessageOn, setBulkMessageOn] = useState<boolean>(false);
  const toggleBulkMessage = useCallback(() => {
    setBulkMessageOn(!bulkMessageOn);
    CookieStorage.set("bulkMessage", !bulkMessageOn);
  }, [bulkMessageOn]);

  const [mcPhase2MediaOn, setMCPhase2MediaOn] = useState<boolean>(false);
  const toggleMCPhase2Media = useCallback(() => {
    setMCPhase2MediaOn(!mcPhase2MediaOn);
    CookieStorage.set("MCPhase2Media", !mcPhase2MediaOn, {
      sameSite: "none",
      secure: true,
    });
    location.reload();
  }, [mcPhase2MediaOn]);

  const [csAgentMCChatOn, setCSAgentMCChatOn] = useState<boolean>(false);
  const toggleCSAgentMCChat = useCallback(() => {
    setCSAgentMCChatOn(!csAgentMCChatOn);
    CookieStorage.set("csAgentChat", !csAgentMCChatOn, {
      sameSite: "none",
      secure: true,
    });
    location.reload();
  }, [csAgentMCChatOn]);

  useEffect(() => {
    const hasBulkMessageCookie = CookieStorage.get("bulkMessage");
    if (hasBulkMessageCookie) {
      setBulkMessageOn(!!hasBulkMessageCookie);
    }

    const mcPhase2MediaCookie = CookieStorage.get("MCPhase2Media");
    if (mcPhase2MediaCookie) {
      setMCPhase2MediaOn(!!mcPhase2MediaCookie);
    }

    const csAgentChatCookie = CookieStorage.get("csAgentChat");
    if (csAgentChatCookie) {
      setCSAgentMCChatOn(!!csAgentChatCookie);
    }
  }, []);

  return (
    <>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"Message Center Phase 2 Video and Audio"}
          onLabel={"On"}
          offLabel={"Off"}
          onChange={toggleMCPhase2Media}
          isChecked={mcPhase2MediaOn}
        />
      </div>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"Bulk Messaging"}
          onLabel={"On"}
          offLabel={"Off"}
          onChange={toggleBulkMessage}
          isChecked={bulkMessageOn}
        />
      </div>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"CS Agent MC Chat"}
          onLabel={"On"}
          offLabel={"Off"}
          onChange={toggleCSAgentMCChat}
          isChecked={csAgentMCChatOn}
        />
      </div>
    </>
  );
};

export default inject("broadcastStore")(observer(UpcomingFeatures));
