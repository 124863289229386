import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import validator from "validator";
import {
  CAMS_MODEL_SESSION_STORAGE_KEYS,
  CAMS_MODELS_STORAGE_KEYS,
  CAMS_STUDIOS_STORAGE_KEYS,
} from "library/core/config/storage";
import config from "core/config";
import axios from "axios";
import {
  ILoginRegisterForm,
  ILoginRegisterFormErrors,
  IRegisterFormData,
} from "./interfaces";
import {
  AuthStoreInitialData,
  LOGIN_FORM_DATA_DEFAULTS,
  LOGIN_REGISTER_FORM_DEFAULTS,
  LOGIN_REGISTER_FORM_ERRORS_DEFAULTS,
  LOGOUT_LOCALSTORAGE_KEY,
  REGISTER_FORM_DATA_DEFAULTS,
} from "./consts";
import {
  AuthenticationTokenResponse,
  ForgotPasswordRequest,
  LoginFormData,
  LoginRequest,
  RegisterRequest,
} from "./types";
import { openRegisterModal } from "./utils";
import { layoutStore } from "library/core/stores/layout/LayoutStore";
import { modalStore } from "library/core/stores/modal";
import { validationStore } from "library/core/stores/validation/ValidationStore";
import { logger } from "library/core/utility";
import { NullableString, UserRole } from "library/core/types";
import CookieStorage from "library/core/utility/cookie-storage";
import { RegistrationStep, RegistrationStepShorthand } from "./enums";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { recaptchaStore } from "library/components/_tailwind/recaptcha/store";
import { ID_CERTIFICATION_FORM_REQUIRED_COUNTRIES } from "../../../common/sign-up/model/const";
import LocalStorage from "library/core/utility/local-storage";
import {
  ProfileDocumentType,
  UserAccountStatus,
} from "../../../common/my-page/stores/profile/enums";
import SessionStorage from "library/core/utility/session-storage";
import { ICountry } from "library/core/interfaces";
import { Agreement } from "common/my-page/stores/profile/types";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { COUNTRY_PHONE_NUMBERS_LIST } from "library/core/constants";
import { broadcastStrategy } from "common/broadcast/_stores/BroadcastStrategy";
import {
  accountSettingsStore,
  earningsStore,
  logToGraylog,
  mediaManagerStore,
  messageStore,
  profileStore,
} from "..";
import { getUserRoleFromDomain } from "library/core/stores/theme/utils";

const logPrefix = "[AuthStore]:";

export default class AuthStore {
  public isReady: boolean = config.flightModeOn || AuthStoreInitialData.isReady;
  public loginRegisterErrors: ILoginRegisterFormErrors =
    AuthStoreInitialData.loginRegisterErrors;
  public loginRegisterForm: ILoginRegisterForm =
    AuthStoreInitialData.loginRegisterForm;
  public registerActiveStep: RegistrationStep =
    AuthStoreInitialData.registerActiveStep;
  public isActionInProgress: boolean = AuthStoreInitialData.isActionInProgress;
  public loginInProgress: boolean = AuthStoreInitialData.loginInProgress;
  public logoutInProgress: boolean = AuthStoreInitialData.logoutInProgress;
  public isLoggedIn: boolean =
    config.flightModeOn || AuthStoreInitialData.isLoggedIn;
  public rememberMe: boolean = AuthStoreInitialData.rememberMe;
  public userRole: UserRole | null = AuthStoreInitialData.userRole;
  public isRefreshingToken: boolean = AuthStoreInitialData.isRefreshingToken;
  public isRetryingRefreshToken: boolean =
    AuthStoreInitialData.isRetryingRefreshToken;
  public isLoadingAgreements: boolean = AuthStoreInitialData.loadingAgreements;
  public agreements: Agreement[] = AuthStoreInitialData.agreements;
  public formRegistrationData: IRegisterFormData =
    AuthStoreInitialData.formRegistrationData;
  public loginFormData: LoginFormData = AuthStoreInitialData.loginFormData;
  public failedRequestQueue: any[] = AuthStoreInitialData.failedRequestQueue;
  public accountSuspensionReason: string | null =
    AuthStoreInitialData.accountSuspensionReason;
  public countries: ICountry = AuthStoreInitialData.countries;
  public isLoading: boolean = false;
  private topWindowRefreshToken: string | null = null;
  private topWindowAccessToken: string | null = null;
  private localRefreshToken: string | null = null;
  private localAccessToken: string | null = null;
  private refreshInterval: any = null;
  private refreshTokenRetryExponent =
    AuthStoreInitialData.refreshTokenRetryExponent;
  private refreshTokenRetryTimeout;
  private retryMaxExponent = 14;

  constructor() {
    makeAutoObservable<
      AuthStore,
      "onAxiosResponseInterceptorError" | "processFailedRequestQueue"
    >(this);

    this.init();
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  public resetStore() {
    Object.entries(AuthStoreInitialData).map(
      ([key, value]) => (this[key] = value)
    );
  }

  init = async (): Promise<void> => {
    const userRole = getUserRoleFromDomain();
    this.setUserRole(userRole);

    this.initCountries();
    this.initTopWindowCookies();

    // set recaptcha v3 on
    recaptchaStore.setUseRecaptchaV3(true);

    // when a studio wants to sign up a new model from studio dashboard
    const referringStudio = this.getStudioUsernameFromQueryString();
    if (referringStudio) {
      this.setStudioReferral(referringStudio);
    }

    const registrationStep: RegistrationStep | null =
      this.getRegistrationStepFromQueryString();

    if (registrationStep) {
      LocalStorage.set("registrationStep", registrationStep);
    }

    const logoutUserRoles = this.getLogoutUserRoles();

    if (logoutUserRoles && logoutUserRoles.length > 0) {
      this.logoutUsersFromArray(logoutUserRoles);
      this.isLoggedIn = false;
      this.isReady = true;
    } else {
      await this.resumeAuthentication();
    }
  };

  private initCountries = async () => {
    try {
      const { data } = await api.countries.get();

      /*US: "United States",
        CA: "Canada",
        AU: "Australia",
        NZ: "New Zealand",
        GB: "United Kingdom",*/

      const sortedCountries = this.sortCountryListByMostVisits(
        data?.ISO_3166_country_list
      );
      this.countries = sortedCountries;
      validationStore.setCountries(this.countries);
    } catch (error) {}
  };

  private sortCountryListByMostVisits = (list: ICountry) => {
    const US = list["US"];
    const CA = list["CA"];
    const AU = list["AU"];
    const NZ = list["NZ"];
    const GB = list["GB"];
    delete list["US"];
    delete list["CA"];
    delete list["AU"];
    delete list["NZ"];
    delete list["GB"];

    return {
      US,
      CA,
      AU,
      NZ,
      GB,
      ...list,
    };
  };

  private initTopWindowCookies = (): void => {
    window.addEventListener("message", event => {
      const { action, key, value } = event?.data;
      if (action === "return_cookies_get") {
        switch (key) {
          case CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN:
            this.topWindowRefreshToken = value;
            break;
          case CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN:
            this.topWindowRefreshToken = value;
            break;
          case CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN:
            this.topWindowAccessToken = value;
            break;
          case CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN:
            this.topWindowAccessToken = value;
            break;
        }
      }
    });
  };

  private getRefreshTokenFromQueryString = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshToken = urlParams.get("r");
    const urlResources = window.location.pathname.split("/");
    const isLoginUrl =
      urlResources && urlResources[1] && urlResources[1].includes("login");

    if (isLoginUrl && refreshToken && refreshToken.trim() !== "") {
      return refreshToken;
    }

    return null;
  };

  private getLiteModeFromQueryStringOrCookie = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromUrl = urlParams.get("lite");
    const fromCookie = CookieStorage.get("dolitemode");
    if (fromCookie === true || fromUrl === "true") {
      return true;
    } else {
      return false;
    }
  };

  private setLiteModePayoutPercentageFromQSOrCookie = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromUrl = urlParams.get("pp");
    if (fromUrl) {
      CookieStorage.set("model_perc", fromUrl);
    }
  };

  private handleDebugCookie = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const debugCookie = urlParams.get("debugCookie");

    if (debugCookie && typeof debugCookie === "string") {
      const parsedCookie = debugCookie.split(":");

      if (parsedCookie.length > 1) {
        if (parsedCookie[1] === "delete") {
          logger.log("handleDebugCookie remove", parsedCookie[0]);
          CookieStorage.remove(parsedCookie[0]);
        } else {
          logger.log("handleDebugCookie set", parsedCookie[0], parsedCookie[1]);
          CookieStorage.set(parsedCookie[0], parsedCookie[1]);
        }
      }
    }
  };

  private getStudioUsernameFromQueryString = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const studioUsername = urlParams.get("studio");

    if (studioUsername && studioUsername.trim() !== "") {
      return studioUsername;
    }

    return null;
  };

  private getRegistrationStepFromQueryString = (): RegistrationStep | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const registrationStep = urlParams.get("registrationStep");

    if (registrationStep && registrationStep.trim() !== "") {
      if (registrationStep === RegistrationStepShorthand.document_upload) {
        return this.userRole === "model"
          ? RegistrationStep.MODEL_DOCUMENT_SELECTION_AND_UPLOAD
          : RegistrationStep.STUDIO_DOCUMENT_SELECTION_AND_UPLOAD;
      } else if (registrationStep === RegistrationStepShorthand.agreement) {
        return this.userRole === "model"
          ? RegistrationStep.MODEL_AGREEMENT
          : RegistrationStep.STUDIO_AGREEMENT;
      } else if (registrationStep === RegistrationStepShorthand.summary) {
        return this.userRole === "model"
          ? RegistrationStep.MODEL_SUMMARY
          : RegistrationStep.STUDIO_SUMMARY;
      }
    }

    return null;
  };

  private getLogoutUserRoles = (): Array<UserRole> | undefined => {
    const urlParams = new URLSearchParams(window.location.search);
    const logoutValue = urlParams.get("logout");
    const urlResources = window.location.pathname.split("/");
    const isRegisterUrl =
      urlResources && urlResources[1] && urlResources[1].includes("register");

    if (isRegisterUrl && logoutValue && logoutValue.trim() !== "") {
      const valueArray = logoutValue.includes(",")
        ? logoutValue.split(",")
        : [logoutValue];

      return valueArray as UserRole[];
    }

    return undefined;
  };

  public static getAccessToken() {
    const modelsToken = CookieStorage.get(
      CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN
    );
    const studiosToken = CookieStorage.get(
      CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN
    );

    return modelsToken || studiosToken || this.getAccessToken();
  }

  public static getRefreshToken() {
    const modelsToken = CookieStorage.get(
      CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN
    );
    const studiosToken = CookieStorage.get(
      CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN
    );

    return modelsToken || studiosToken || this.getRefreshToken();
  }

  private logoutUsersFromArray = (logoutUserRoles: UserRole[]) => {
    if (logoutUserRoles && logoutUserRoles.length > 0) {
      if (logoutUserRoles.includes("model")) {
        this.removeModelAuthenticationKeysFromCookies();
      }
      if (logoutUserRoles.includes("studio")) {
        this.removeStudioAuthenticationKeysFromCookies();
      }
    }
  };

  resumeAuthentication = async (): Promise<string | void> => {
    const userRole = getUserRoleFromDomain();
    const queryStringRefreshToken = this.getRefreshTokenFromQueryString();
    this.isLoading = true;
    this.log("resumeAuthentication started");

    if (!!queryStringRefreshToken) {
      this.log("resumeAuthentication resetting previous session");
      this.resetToken();
      this.removeAuthenticationStorageKeys();
      this.removeSessionStorageKeys();
    }

    if (userRole) {
      this.log("resumeAuthentication found userRole, getting refreshToken");
      try {
        const refreshToken = this.getRefreshToken();
        const isLiteMode = this.getLiteModeFromQueryStringOrCookie();
        layoutStore?.setIsLiteMode(isLiteMode);
        this.setLiteModePayoutPercentageFromQSOrCookie();
        this.handleDebugCookie();
        if (refreshToken && userRole) {
          this.log(
            "resumeAuthentication found refreshToken and userRole, getting authResponse from BE"
          );
          const authResponse: AuthenticationTokenResponse =
            await this.getAuthResponseFromBackendViaRefreshToken();

          await this.loginUser(authResponse, userRole);
          this.isLoggedIn = true;
          const { access } = authResponse;

          if (isLiteMode) {
            // fetch new refresh token
            this.handleTokenRefresh();
            this.topWindowRefreshToken = refreshToken;
            this.setAccessToken(access, true);
            this.topWindowAccessToken = access;
          }

          this.log("resumeAuthentication logged user in");
          return access;
        } else {
          this.log(
            "resumeAuthentication could not find refreshToken or userRole, resettingToken"
          );
          this.resetToken();
        }
      } catch (e) {
        this.log("resumeAuthentication failed", e);
        await this.logout(true);
      } finally {
        this.isLoading = false;
        this.isReady = true;
        this.log("resumeAuthentication finished");
      }
    }
  };

  getAuthResponseFromBackendViaRefreshToken =
    async (): Promise<AuthenticationTokenResponse> => {
      const userRole = getUserRoleFromDomain();
      const refreshToken = this.getRefreshToken();
      const { data } = await api
        .getTokenRefreshEndpoint(userRole as any)
        .post({ refresh_token: refreshToken });
      if (!data || !data.access) {
        logToGraylog(
          logPrefix,
          "Got bad response when logging in via refresh token",
          {
            error: "Got bad response when logging in via refresh token",
            sent: {
              refreshToken,
            },
            response: data,
            userRole,
          }
        );
      }
      this.setRefreshToken(data.refresh);
      this.setAccessToken(data.access);

      return data;
    };

  setToken = ({ access, refresh }: { access; refresh; profile_type }) => {
    this.setRefreshToken(refresh);
    this.setAccessToken(access);

    api.setTokenHeader(access);
    api.setInterceptorResponseFunctions(
      response => response,
      this.onAxiosResponseInterceptorError
    );
    this.isLoggedIn = true;
  };

  resetToken = () => {
    api.deleteTokenHeader();
    this.setRefreshToken(null);
    this.setAccessToken(null);
    this.resetAxiosInterceptors();
  };

  private resetAxiosInterceptors = () => {
    api.removeInterceptorResponseFunctions();
  };

  handleTokenRefresh = () => {
    return new Promise((resolve, reject) => {
      const profileType: UserRole | null = getUserRoleFromDomain();
      const refresh_token = this.getRefreshToken();
      if (profileType && refresh_token) {
        return api
          .getTokenRefreshEndpoint(profileType)
          .post({ refresh_token })
          .then(({ data }) => {
            this.setRefreshToken(data?.refresh);
            this.setAccessToken(data?.access);
            api.setTokenHeader(data?.access);
            resolve(data);
          })
          .catch(_error => {
            console.log(
              "onAxiosResponseInterceptorError:",
              _error,
              _error.config
            );
            reject(_error);
          });
      }

      reject(new Error("Profile type or Refresh token is not set"));
    });
  };

  attachTokenToRequest = (request, token) => {
    request.headers["Authorization"] = "Bearer " + token;
  };

  shouldInterceptError = error => {
    return (
      !this.loginInProgress &&
      error?.response &&
      (((error?.response?.status === 403 ||
        (error?.response?.status === 401 &&
          error?.request?.responseURL?.startsWith(config.walletApiUrl))) &&
        !error?.request?.responseURL?.includes("/jwt/refresh/") &&
        !error?.request?.responseURL?.includes("/jwt/token/") &&
        !error?.request?.responseURL?.includes("/logout/")) ||
        (error?.response?.status !== 403 &&
          error?.request?.responseURL?.includes("/jwt/refresh/") &&
          !this.isRefreshingToken))
    );
  };

  public onAxiosResponseInterceptorError = error => {
    this.log("onAxiosResponseInterceptorError start");
    const originalRequest = error?.config;
    this.log("interceptor middleware received error", error);

    if (!this.shouldInterceptError(error)) {
      return Promise.reject(error);
    }

    if (this.isRefreshingToken) {
      return new Promise((resolve, reject) => {
        this.failedRequestQueue.push({ resolve, reject });
      })
        .then(token => {
          this.attachTokenToRequest(originalRequest, token);
          return axios.request(originalRequest);
        })
        .catch(_error => {
          return Promise.reject(_error);
        });
    }

    this.isRefreshingToken = true;
    return new Promise((resolve, reject) => {
      const isRefreshTokenError =
        error?.request?.responseURL?.includes("/jwt/refresh/");

      if (this.refreshTokenRetryExponent < this.retryMaxExponent) {
        this.refreshTokenRetryExponent++;
      }
      const _delay = Math.ceil(
        Math.random() * ((1 << this.refreshTokenRetryExponent) * 500)
      );
      const delay =
        _delay < 3000 ? 3000 : _delay > 5 * 60000 ? 5 * 60000 : _delay;

      this.log(
        "onAxiosResponseInterceptorError refresh exponent",
        this.refreshTokenRetryExponent
      );
      this.log("onAxiosResponseInterceptorError refresh delay", delay);
      this.refreshTokenRetryTimeout = setTimeout(async () => {
        try {
          this.log("onAxiosResponseInterceptorError handling refresh");
          this.handleTokenRefresh()
            .then(({ access }: any) => {
              this.refreshTokenRetryExponent =
                AuthStoreInitialData.refreshTokenRetryExponent;

              if (originalRequest) {
                this.log(
                  "onAxiosResponseInterceptorError processing failed request",
                  originalRequest
                );
                this.attachTokenToRequest(originalRequest, access);
                this.processFailedRequestQueue(null, access);
                resolve(axios.request(originalRequest));
              }
            })
            .catch(_error => {
              this.processFailedRequestQueue(_error, null);
              if (
                (error?.response?.status === 403 && isRefreshTokenError) ||
                !isRefreshTokenError
              ) {
                this.logout();
              }
              reject(_error);
            })
            .finally(() => {
              this.isRefreshingToken = false;
              this.log("onAxiosResponseInterceptorError end");
            });
        } catch (e) {
          reject(e);
        }
      }, delay);
    });
  };

  private processFailedRequestQueue = (error, token = null) => {
    this.failedRequestQueue.forEach(originalRequest => {
      if (error) {
        originalRequest.reject(error);
      } else {
        originalRequest.resolve(token);
      }
    });

    this.failedRequestQueue = [];
  };

  public toggleRememberMe = (shouldRemember: boolean) => {
    // will be done as cookie, will delete tokens on app start when cookie is there
    this.rememberMe = shouldRemember;
  };

  public get hasUserLoaded() {
    return profileStore.hasProfileLoaded;
  }

  public loginUser = async (
    tokenResponse: AuthenticationTokenResponse,
    userRole: UserRole | null
  ) => {
    if (tokenResponse && userRole) {
      this.setToken(tokenResponse);
      await this.getUserData();

      // start fetching entire contact list
      messageStore.getContacts();

      // if access and refresh are matching, immediately refresh to get real refresh token
      if (tokenResponse.access === tokenResponse.refresh) {
        this.handleTokenRefresh();
      }

      // try to refresh before a 403 error occurs, triggering a refresh
      this.refreshInterval = setInterval(() => {
        if (!this.isRefreshingToken) {
          this.handleTokenRefresh();
        }
      }, 1000 * 480 * 1);
    }
  };

  private getUserData = async () => {
    await profileStore.initProfile();
    await accountSettingsStore.init();
    if (this.userRole === "model") {
      await mediaManagerStore.initAllMedia();
    }
  };

  private requestLogin = async (
    emailOrUn: string,
    password: string,
    captchaKey: NullableString
  ) => {
    const isEmail = validator.isEmail(emailOrUn);

    try {
      const res = await api.getLoginEndpoint(this.userRole).post(
        recaptchaStore.attachRecaptchaTokenToRequest(
          {
            ...(isEmail ? { email: emailOrUn } : { username: emailOrUn }),
            password,
            site: "cams",
          },
          captchaKey
        ) as LoginRequest
      );
      if (res.name === "Error") {
        throw res;
      } else if (res && res.data) {
        await this.loginUser(res.data, this.userRole);
      }
    } catch (errorResponse) {
      const error = errorResponse.error || errorResponse;

      validationStore.storeBackendErrors(error, undefined, [
        "non_field_errors",
        "email",
      ]);
      recaptchaStore.checkResponseForRecaptchaError(errorResponse);
      throw new Error(errorResponse);
    }
  };

  public setUserRole = (role: UserRole | null) => {
    if (role) {
      this.userRole = role;
    }
  };

  public login = async (captchaKey: NullableString): Promise<void> => {
    if (this.loginInProgress || this.isActionInProgress) return;
    this.log("login started");

    const { emailOrUsername, password } = this.loginFormData;
    const allInputsValid = validationStore.validateMultiple(
      ["emailOrUsername", "captchaKey"],
      {
        emailOrUsername,
        captchaKey,
      }
    );
    if (allInputsValid) {
      this.loginInProgress = true;
      try {
        await this.requestLogin(emailOrUsername, password, captchaKey);
        this.removeGlobalLogoutStorageKeys();
        this.resetLoginRegisterFormState();
        modalStore.closeAuthModal();
        this.openRegisterPopupIfLocalStorageExists();

        // show summary if user is denied or incomplete
        if (
          profileStore?.isCurrentAccountStatus(UserAccountStatus.DENIED) ||
          profileStore?.isCurrentAccountStatus(UserAccountStatus.INCOMPLETE)
        ) {
          this.openRegisterModalToCompleteProfile(true, true);
        }
      } catch (error) {
        this.log("login failed", error);
        recaptchaStore.checkResponseForRecaptchaError(error);
      } finally {
        this.loginInProgress = false;
        this.log("login finished");
      }
    }
  };

  private openRegisterPopupIfLocalStorageExists = () => {
    const registrationStep = LocalStorage.get("registrationStep");
    if (registrationStep) {
      this.setRegistrationStep(registrationStep);
      this.openRegisterModalToCompleteProfile(false, true);
      LocalStorage.remove("registrationStep");
    }
  };

  private removeGlobalLogoutStorageKeys = () => {
    CookieStorage.remove(CAMS_MODELS_STORAGE_KEYS.GLOBAL_LOGOUT_KEY);
    CookieStorage.remove(CAMS_STUDIOS_STORAGE_KEYS.GLOBAL_LOGOUT_KEY);
  };

  public removeModelAuthenticationKeysFromCookies = () => {
    CookieStorage.remove(CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN);
    CookieStorage.remove(CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN);
    this.localRefreshToken = null;
    this.localAccessToken = null;
  };
  private removeStudioAuthenticationKeysFromCookies = () => {
    CookieStorage.remove(CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN);
    CookieStorage.remove(CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN);
    this.localRefreshToken = null;
    this.localAccessToken = null;
  };

  private removeAuthenticationStorageKeys = () => {
    this.removeModelAuthenticationKeysFromCookies();
    this.removeStudioAuthenticationKeysFromCookies();
    this.topWindowAccessToken = null;
    this.topWindowRefreshToken = null;
  };

  private removeSessionStorageKeys = () => {
    SessionStorage.remove(CAMS_MODEL_SESSION_STORAGE_KEYS.SUMMARY_MODAL);
  };

  public logout = async (logoutAllTabs?: boolean): Promise<void> => {
    if (!this.logoutInProgress) {
      this.logoutInProgress = true;
      await broadcastStrategy.disconnect("logout");
      earningsStore.resetStore();
      profileStore.resetStore();
      messageStore.resetStore();
      mediaManagerStore.resetStore();
      this.resetLoginRegisterFormState();
      this.resetToken();
      this.removeAuthenticationStorageKeys();
      this.removeSessionStorageKeys();
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
      if (logoutAllTabs) {
        CookieStorage.set(LOGOUT_LOCALSTORAGE_KEY, true);
      } else {
        CookieStorage.remove(LOGOUT_LOCALSTORAGE_KEY);
      }
      if (!!this.refreshTokenRetryTimeout) {
        clearTimeout(this.refreshTokenRetryTimeout);
      }
      modalStore.closeAuthModal();
      this.isLoggedIn = false;
      this.logoutInProgress = false;
    }
  };

  updateRegistrationFormField = (userType: string | null, field: any) => {
    const validField = field && field.name;
    if (userType && validField) {
      if (field.name === "document_type") {
        this.formRegistrationData[userType].document_front_key = "";
        if (field.value === "us_military_id") {
          this.formRegistrationData[userType].document_back_key = "";
        }
        if (userType !== "studio_document") {
          this.formRegistrationData[userType].document_verification_key = "";
        }
      }
    }

    if (userType && validField) {
      this.formRegistrationData[userType][field.name] = field.value;
    }

    if (!userType && validField) {
      this.formRegistrationData[field.name] = field.value;
    }
  };

  updateLoginFormField = (field: any) => {
    const validField = field && field.name;
    if (validField) {
      this.loginFormData[field.name] = field.value;
    }
  };

  public validateFirstStepData = async () => {
    try {
      this.log("validateFirstStepData started");
      this.isActionInProgress = true;
      const userRole = this.userRole;
      const { email, password } = this.formRegistrationData;

      if (userRole) {
        const validationObject: any = {
          email,
          password,
        };

        const allInputsValid =
          validationStore.validateMultipleObj(validationObject);

        if (allInputsValid) {
          if (allInputsValid && userRole) {
            let nextStep = RegistrationStep.MODEL_ACCOUNT_INFO;

            switch (userRole) {
              case "model":
                nextStep = RegistrationStep.MODEL_ACCOUNT_INFO;
                break;
              case "studio":
                nextStep = RegistrationStep.STUDIO_ACCOUNT_INFO;
                break;
            }

            this.loginRegisterForm.user_type = userRole;
            this.setRegistrationStep(nextStep);
          }
        }
      }
    } catch (error) {
      this.log("validateFirstStepData failed", error);
    } finally {
      this.isActionInProgress = false;
      this.log("validateFirstStepData finished");
    }
  };

  get registerFormPhoneNumber() {
    if (this.userRole === "studio") {
      return `+${
        COUNTRY_PHONE_NUMBERS_LIST[
          this.formRegistrationData.studio.phone_number_country_code
        ]
      }${this.formRegistrationData.studio.phone_number}`;
    }

    return "";
  }

  public submitAccountRegistration = async (captchaKey: string) => {
    const userRole = this.userRole;
    if (userRole) {
      let nextStep: RegistrationStep | undefined =
        RegistrationStep.MODEL_DOCUMENT_SELECTION_AND_UPLOAD;
      const { email, password, model, studio } = this.formRegistrationData;

      switch (userRole) {
        case "model":
          nextStep = RegistrationStep.MODEL_SUMMARY;
          break;
        case "studio":
          nextStep = RegistrationStep.STUDIO_DOCUMENT_SELECTION_AND_UPLOAD;
          break;
      }

      const validationObject: any = {
        email,
        password,
        captchaKey,
      };

      switch (userRole) {
        case "studio":
          validationObject.username = studio.username;
          validationObject.studio = studio;
          break;
        case "model":
          validationObject.username = model.username;
          validationObject.model = model;
          break;
      }

      const allInputsValid = validationStore.validateMultipleObj(
        validationObject,
        {
          promo_code: { canBeEmpty: true },
          other_name: { canBeEmpty: true },
          state: { canBeEmpty: true },
        }
      );

      if (allInputsValid) {
        this.loginRegisterForm.user_type = userRole;
        try {
          this.isActionInProgress = true;
          let requestObject = recaptchaStore.attachRecaptchaTokenToRequest(
            {
              password,
              email,
              profile_type: userRole,
              studio_referral: this.getStudioReferral(),
              model_referral: this.getModelReferral(),
              site: "cams",
            },
            captchaKey
          ) as RegisterRequest;
          switch (userRole) {
            case "studio":
              requestObject = {
                ...requestObject,
                ...this.formRegistrationData.studio,
                phone_number: this.registerFormPhoneNumber,
              };
              break;
            case "model":
              requestObject = {
                ...requestObject,
                ...this.formRegistrationData.model,
              };
              break;
          }

          const signupResp: {
            data: AuthenticationTokenResponse;
          } = await api.getBaseEndpoint(userRole).post(requestObject);

          this.setToken(signupResp?.data);
          await profileStore.initProfile();
          if (nextStep) {
            this.setRegistrationStep(nextStep);
          } else {
            this.completeSummaryStep();
          }
          this.removeReferralsFromCookies();
        } catch (error) {
          validationStore.storeBackendErrors(error, undefined, [
            "non_field_errors",
            "email",
          ]);

          // Set the errors recieved from the response.
          // Note:  Response returns text, should probably
          //   return codes which we can map to text on the FE and translate.
          Object.keys(error.response?.data).forEach(key => {
            validationStore.setError(key, error.response.data[key]);
          });
          if (nextStep) {
            this.setRegistrationStepBasedOnValidation(
              validationStore.errors,
              nextStep
            );
          }
          recaptchaStore.checkResponseForRecaptchaError(error);
        } finally {
          this.isActionInProgress = false;
        }
      }
    }
  };

  public completeCertificationFormUploadStep = async () => {
    const nextStep = RegistrationStep.MODEL_AGREEMENT;
    const submitData: any = {
      document_id_cert_key:
        this.formRegistrationData.model_document.document_id_cert_key,
    };
    const allInputsValid = validationStore.validateMultipleObj(
      submitData,
      undefined,
      false
    );
    if (allInputsValid) {
      await api
        .getMeEndpoint(this.userRole)
        .patch({ document: { ...submitData } });
      await profileStore.initProfile();
      this.setRegistrationStep(nextStep);
    }
  };

  public completeDocumentUploadStep = async () => {
    try {
      this.log("completeDocumentUploadStep started");
      this.isActionInProgress = true;
      const isModelRegistration = (this.userRole || "model") === "model";
      let nextStep;

      const submitData: any = isModelRegistration
        ? this.formRegistrationData.model_document
        : this.formRegistrationData.studio_document;
      submitData.expire_date = new Date(submitData?.expire_date).toISOString();

      if (
        isModelRegistration &&
        submitData.document_type !== "us_military_id" &&
        submitData.document_type !== "national_id"
      ) {
        delete submitData.document_back_key;
      }

      if (isModelRegistration) {
        if (
          this.formRegistrationData.model_document?.issue_country &&
          ID_CERTIFICATION_FORM_REQUIRED_COUNTRIES.includes(
            this.formRegistrationData.model_document?.issue_country
          )
        ) {
          nextStep = RegistrationStep.MODEL_CERTIFICATION_UPLOAD;
        } else {
          nextStep = RegistrationStep.MODEL_AGREEMENT;
        }
      } else {
        nextStep = RegistrationStep.STUDIO_AGREEMENT;
      }

      const allInputsValid = validationStore.validateMultipleObj(
        submitData,
        undefined,
        false
      );
      if (allInputsValid) {
        await api
          .getMeEndpoint(this.userRole)
          .patch({ document: { ...submitData } });
        await profileStore.initProfile();
        if (nextStep) {
          this.setRegistrationStep(nextStep);
        } else {
          modalStore.closeAllModals();
        }
      }
    } catch (error) {
      validationStore.storeBackendErrors(error);
      this.log("completeDocumentUploadStep failed", error);
    } finally {
      this.isActionInProgress = false;
      this.log("completeDocumentUploadStep finished");
    }
  };

  public completeAgreementStep = async (
    goToNextStep: boolean = false,
    agreementID: string
  ) => {
    try {
      this.log("completeAgreementStep started");
      this.isActionInProgress = true;
      const isModelRegistration = (this.userRole || "model") === "model";
      const nextStep = isModelRegistration
        ? RegistrationStep.MODEL_SUMMARY
        : RegistrationStep.STUDIO_SUMMARY;
      const validateSignature = validationStore.validate(
        "signature",
        this.formRegistrationData.signed_agreement.signature
      );
      if (validateSignature) {
        await api.getMeEndpoint(this.userRole).patch({
          signed_agreement: {
            ...this.formRegistrationData.signed_agreement,
            agreement: agreementID,
          },
        });
        this.formRegistrationData.signed_agreement.signature = "";
        if (goToNextStep) {
          await profileStore.initProfile();
          this.setRegistrationStep(nextStep);
        }
        return true;
      }
      return false;
    } catch (error) {
      this.log("completeAgreementStep failed", error);
    } finally {
      this.isActionInProgress = false;
      this.log("completeAgreementStep finished");
    }
  };

  public completeSummaryStep = () => {
    modalStore.closeAuthModal();
    this.resetLoginRegisterFormState();
  };

  public getAgreements = async () => {
    try {
      this.log("getAgreements started");
      this.isLoadingAgreements = true;
      const {
        data: { results },
      } = await api.getAgreements.get();
      if (results) {
        this.agreements = results;
      }
    } catch (error) {
      this.log("getAgreements failed", error);
    } finally {
      this.isLoadingAgreements = false;
      this.log("getAgreements finished");
    }
  };
  public updateLoginRegisterInput(input: string, value: any) {
    this.loginRegisterForm = {
      ...this.loginRegisterForm,
      [input]: value,
    };
  }

  public setRegistrationStep = (step: RegistrationStep) => {
    this.registerActiveStep = step;
  };

  public setRegistrationStepBasedOnValidation = (
    errors: any,
    nextStep: RegistrationStep
  ) => {
    if (errors) {
      if (errors.email || errors.password || errors.captcha) {
        this.setRegistrationStep(RegistrationStep.AUTH_CREDENTIALS);
        return;
      }
      this.setRegistrationStep(this.registerActiveStep);
      return;
    }
    this.setRegistrationStep(nextStep);
  };

  public openRegisterModalToCompleteProfile = (
    showSummary: boolean = false,
    forceOpen: boolean = false
  ) => {
    const profileData: any = profileStore.profile;
    const denyReasons = profileData.deny_reasons;
    const isModel = getUserRoleFromDomain() === "model";
    if (isModel && !forceOpen) {
      // models only complete their profile when they click on broadcast link
      return;
    }

    const nextStepPrefix = isModel ? "MODEL" : "STUDIO";
    if (isModel) {
      this.formRegistrationData.model_document = {
        document_type: profileData?.document?.document_type,
        issue_country: profileData?.document?.issue_country,
        document_front_key: profileData?.document?.document_front,
        document_back_key: profileData?.document?.document_back,
        document_verification_key: profileData?.document?.document_verification,
        expire_date: profileData?.document?.expire_date,
      };
    } else {
      this.formRegistrationData.studio_document = {
        document_type: "studio",
        issue_country: profileData?.document?.issue_country,
        document_front_key: profileData?.document?.document_front,
        expire_date: profileData?.document?.expire_date,
      };
    }
    if (showSummary) {
      this.registerActiveStep = RegistrationStep[`${nextStepPrefix}_SUMMARY`];
    } else if (profileData?.document) {
      if (
        profileData.missing_signup_fields?.length > 2 ||
        denyReasons?.expire_date ||
        denyReasons?.document_front ||
        denyReasons?.document_back ||
        denyReasons?.document_verification
      ) {
        this.registerActiveStep =
          RegistrationStep[`${nextStepPrefix}_DOCUMENT_SELECTION_AND_UPLOAD`];
      } else if (
        (isModel &&
          profileData?.missing_signup_fields?.includes(
            `document.${ProfileDocumentType.CERTIFICATION_FORM}`
          )) ||
        denyReasons?.document_id_cert
      ) {
        this.registerActiveStep =
          RegistrationStep[`${nextStepPrefix}_CERTIFICATION_UPLOAD`];
      } else {
        this.registerActiveStep =
          RegistrationStep[`${nextStepPrefix}_AGREEMENT`];
      }
    }

    openRegisterModal();
  };

  private resetLoginRegisterFormState() {
    this.loginRegisterForm = LOGIN_REGISTER_FORM_DEFAULTS;
    this.loginRegisterErrors = LOGIN_REGISTER_FORM_ERRORS_DEFAULTS;
    this.registerActiveStep = RegistrationStep.AUTH_CREDENTIALS;
    this.formRegistrationData = REGISTER_FORM_DATA_DEFAULTS;
    this.loginFormData = LOGIN_FORM_DATA_DEFAULTS;
  }

  public getAccessToken(): string {
    const modelsToken = CookieStorage.get(
      CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN
    );
    const studiosToken = CookieStorage.get(
      CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN
    );

    return (
      modelsToken ||
      studiosToken ||
      this.localAccessToken ||
      this.topWindowAccessToken
    );
  }

  public getRefreshToken(): string {
    const refreshToken = this.getRefreshTokenFromQueryString();
    const modelsToken = CookieStorage.get(
      CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN
    );
    const studiosToken = CookieStorage.get(
      CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN
    );

    if (this.userRole === "model") {
      return (
        modelsToken ||
        this.localRefreshToken ||
        refreshToken ||
        this.topWindowRefreshToken
      );
    } else if (this.userRole === "studio") {
      return (
        studiosToken ||
        this.localRefreshToken ||
        refreshToken ||
        this.topWindowRefreshToken
      );
    }
    return (
      refreshToken || modelsToken || studiosToken || this.topWindowRefreshToken
    );
  }

  private setRefreshToken = (
    refreshToken: string | null,
    postMessage?: boolean
  ): void => {
    const { isLiteMode } = layoutStore!;
    if (refreshToken) {
      switch (this.userRole) {
        case "model": {
          if (postMessage) {
            CookieStorage.setPostMessage(
              CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN,
              refreshToken
            );
          } else {
            CookieStorage.set(
              CAMS_MODELS_STORAGE_KEYS.REFRESH_TOKEN,
              refreshToken,
              isLiteMode
                ? {
                    sameSite: "none",
                    secure: true,
                  }
                : {}
            );
            this.localRefreshToken = refreshToken;
          }
          break;
        }
        case "studio": {
          if (postMessage) {
            CookieStorage.setPostMessage(
              CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN,
              refreshToken
            );
          } else {
            CookieStorage.set(
              CAMS_STUDIOS_STORAGE_KEYS.REFRESH_TOKEN,
              refreshToken
            );
            this.localRefreshToken = refreshToken;
          }
          break;
        }
      }
    } else {
      this.removeAuthenticationStorageKeys();
    }
  };

  private setAccessToken = (
    accessToken: string | null,
    postMessage?: boolean
  ): void => {
    const { isLiteMode } = layoutStore!;
    if (accessToken) {
      switch (this.userRole) {
        case "model": {
          if (postMessage) {
            CookieStorage.setPostMessage(
              CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN,
              accessToken
            );
          } else {
            CookieStorage.set(
              CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN,
              accessToken,
              isLiteMode
                ? {
                    sameSite: "none",
                    secure: true,
                  }
                : {}
            );
            this.localAccessToken = accessToken;
          }
          break;
        }
        case "studio": {
          if (postMessage) {
            CookieStorage.setPostMessage(
              CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN,
              accessToken
            );
          } else {
            CookieStorage.set(
              CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN,
              accessToken
            );
          }
          break;
        }
      }
    } else {
      this.removeAuthenticationStorageKeys();
    }
  };

  private getStudioReferral(): string {
    return CookieStorage.get(CAMS_STUDIOS_STORAGE_KEYS.STUDIO_REFERRAL);
  }
  private getModelReferral(): string {
    return CookieStorage.get(CAMS_MODELS_STORAGE_KEYS.MODEL_REFERRAL);
  }
  private removeStudioReferral(): void {
    CookieStorage.remove(CAMS_STUDIOS_STORAGE_KEYS.STUDIO_REFERRAL);
  }
  private removeModelReferral(): void {
    CookieStorage.remove(CAMS_MODELS_STORAGE_KEYS.MODEL_REFERRAL);
  }

  public removeReferralsFromCookies = () => {
    this.removeStudioReferral();
    this.removeModelReferral();
  };

  public setStudioReferral = (studio: string): void => {
    if (studio) {
      CookieStorage.set(CAMS_STUDIOS_STORAGE_KEYS.STUDIO_REFERRAL, studio);
    }
  };

  public setModelReferral = (model: string): void => {
    if (model) {
      CookieStorage.set(CAMS_MODELS_STORAGE_KEYS.MODEL_REFERRAL, model);
    }
  };

  changePassword = async (
    old_password: string,
    new_password: string,
    new_password_check: string,
    token?: string
  ) => {
    try {
      this.log("changePassword started");
      validationStore?.clearErrors();
      this.isActionInProgress = true;
      if (token) {
        await api.getUpdatePasswordEndpoint(this.userRole).post({
          token,
          password: new_password,
        });
      } else {
        await api.getSetPasswordEndpoint(this.userRole).post({
          old_password,
          new_password,
          new_password_check,
        });
      }

      snackbarStore.enqueueSnackbar({
        message: {
          id: "accountSettings.passwordSettings.passwordUpdateSuccess",
          default: "Your password has been successfully updated",
        },
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      validationStore.storeBackendErrors(error, "changePassword");
      this.log("changePassword failed", config.showAjaxErrors ? error : "");
      throw new Error(error);
    } finally {
      this.isActionInProgress = false;
      this.log("changePassword finished");
    }
  };

  public resetPassword = async (
    emailOrUsername: string,
    recaptchaKey: NullableString
  ) => {
    try {
      this.log("resetPassword started");
      validationStore?.clearErrors();
      this.isActionInProgress = true;
      const isValidEmail = validationStore?.validate("email", emailOrUsername);
      const isValidUsername = validationStore?.validate(
        "username",
        emailOrUsername
      );

      if (isValidEmail || isValidUsername) {
        await api
          .getForgotPasswordEndpoint(this.userRole)
          .post(
            isValidEmail
              ? (recaptchaStore.attachRecaptchaTokenToRequest(
                  { email: emailOrUsername, site: "cams" },
                  recaptchaKey
                ) as ForgotPasswordRequest)
              : (recaptchaStore.attachRecaptchaTokenToRequest(
                  { username: emailOrUsername, site: "cams" },
                  recaptchaKey
                ) as ForgotPasswordRequest)
          );
        snackbarStore.enqueueSnackbar({
          message: {
            id: "accountSettings.passwordSettings.yourPasswordResetEmailSent",
            default:
              "Your password reset email sent, if you are missing the email, check your spam filter or click to resend",
          },
          actions: [
            {
              callback: () => this.resetPassword(emailOrUsername, recaptchaKey),
              parameters: null,
              labelId: "common.resend",
              defaultLabel: "Resend",
            },
          ],
          variant: SnackbarVariants.SUCCESS,
        });
      }
    } catch (error) {
      validationStore.storeBackendErrors(error, "resetPassword", [
        "non_field_errors",
        "email",
      ]);
      recaptchaStore.checkResponseForRecaptchaError(error);
      this.log("resetPassword failed", error);
      throw new Error(error);
    } finally {
      this.isActionInProgress = false;
      this.log("resetPassword finished");
    }
  };

  public resetUsername = async (
    email: string,
    recaptchaKey: NullableString
  ) => {
    try {
      this.log("resetUsername started");
      validationStore?.clearErrors();
      this.isActionInProgress = true;
      const isUsernameValid = validationStore?.validate("email", email);
      if (isUsernameValid) {
        await api
          .getForgotUsernameEndpoint(this.userRole)
          .get(
            recaptchaStore.attachRecaptchaTokenToRequest(
              { email, site: "cams" },
              recaptchaKey
            ) as ForgotPasswordRequest
          );
        snackbarStore.enqueueSnackbar({
          message: {
            id: "accountSettings.passwordSettings.yourUsernameResetEmailSent",
            default:
              "Your username reset e-mail sent, if you are missing the e-mail, check your spam filter or click to resend",
          },
          actions: [
            {
              callback: () => this.resetUsername(email, recaptchaKey),
              parameters: null,
              labelId: "common.resend",
              defaultLabel: "Resend",
            },
          ],
          variant: SnackbarVariants.SUCCESS,
        });
      }
    } catch (error) {
      validationStore.storeBackendErrors(error, "resetUsername");
      this.log("resetUsername failed", error);
      recaptchaStore.checkResponseForRecaptchaError(error);
      throw new Error(error);
    } finally {
      this.isActionInProgress = false;
      this.log("resetUsername finished");
    }
  };
}
