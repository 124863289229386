import React, { useCallback, useMemo } from "react";
import { MessageCenterContactDetails } from "./stores/messages/types";
import Avatar from "library/components/avatar";
import TailwindFlex from "library/components/_tailwind/flex";
import { TBackgroundColor } from "tailwindcss-classnames";
import MessageCenterNavigatorCheckMark from "./message-navigator-checkmark";
import MessageStore from "./stores/messages/MessageStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";

export type MemberContactListFilter = {
  name: string;
  background: TBackgroundColor;
};

type Props = {
  contact: MessageCenterContactDetails;
  onClick: (contact: MessageCenterContactDetails, multiple?: boolean) => void;
  isSelected: boolean;
  isMobile?: boolean;
  preventSendBulkMsg?: boolean;
  showBorder?: boolean;
  messageStore?: MessageStore;
};

const MessageCenterContact: React.ComponentType<Props> = ({
  contact,
  onClick,
  isSelected,
  preventSendBulkMsg = false,
  showBorder = true,
  messageStore,
}) => {
  const { isBulkMessageView } = messageStore!;
  const onContactClick = useCallback(() => {
    if (preventSendBulkMsg) return;
    onClick(contact);
  }, [onClick, contact]);

  const contactsBadges = useMemo(() => {
    const badges: MemberContactListFilter[] = [];
    if (contact.is_fanclub_member) {
      badges.push({
        name: "fc",
        background: "bg-lightGreen-bg-color" as TBackgroundColor,
      });
    }
    if (contact.is_bounty_member) {
      badges.push({
        name: "b",
        background: "bg-lightPink-bg-color" as TBackgroundColor,
      });
    }
    if (contact.is_referral_member) {
      badges.push({
        name: "r",
        background: "bg-lightPink-bg-color" as TBackgroundColor,
      });
    }
    if (
      contact.is_top_admirer &&
      contact.top_admirer_rank > 0 &&
      contact.top_admirer_rank <= 5
    ) {
      badges.push({
        name: `#${contact.top_admirer_rank}`,
        background: "bg-lightRed-bg-color" as TBackgroundColor,
      });
    } else if (contact.is_top_admirer) {
      badges.push({
        name: "TA",
        background: "bg-lightRed-bg-color" as TBackgroundColor,
      });
    }
    if (contact.is_recent_visitor) {
      badges.push({
        name: "RV",
        background: "bg-lightGray-bg-color" as TBackgroundColor,
      });
    }
    if (contact.is_top_spender) {
      badges.push({
        name: "TS",
        background: "bg-lightPurple-bg-color" as TBackgroundColor,
      });
    }
    return badges;
  }, [contact]);

  return (
    <TailwindFlex
      pseudoClasses={
        preventSendBulkMsg
          ? ["hover:bg-transparent"]
          : ["hover:bg-highlight-color"]
      }
      backgroundColor={
        preventSendBulkMsg
          ? "bg-transparent"
          : !isSelected || !isBulkMessageView
          ? "bg-transparent"
          : "bg-secondary-bg-color"
      }
      onClick={onContactClick}
      margin={["m-0"]}
      opacity={preventSendBulkMsg ? "opacity-40" : "opacity-100"}
      cursor={preventSendBulkMsg ? "cursor-default" : "cursor-pointer"}>
      <TailwindFlex
        className={["MessageNavigatorConversation"]}
        borderWidth={showBorder ? ["border-t-2"] : []}
        borderColor={"border-primary-bg-color"}
        style={{
          padding: "0 15px",
        }}>
        <TailwindFlex
          position={"relative"}
          width={"w-auto"}
          height={"h-full"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          style={{
            paddingRight: "15px",
          }}>
          {isSelected && isBulkMessageView && (
            <TailwindFlex
              position='absolute'
              zIndex='z-20'
              style={{
                top: "-2px",
                left: "-14px",
              }}>
              <MessageCenterNavigatorCheckMark />
            </TailwindFlex>
          )}
          <Avatar
            photoURL={contact?.profile_picture}
            size={"small"}
            username={contact?.username}
            randomBackgroundColor={true}
          />
        </TailwindFlex>
        <TailwindFlex height={"h-full"}>
          <TailwindFlex alignItems='items-center' display='flex'>
            {contact?.username}
            <TailwindFlex
              className={["contact-badges"]}
              space='space-x-2'
              margin={["mx-2"]}>
              {contactsBadges?.map(badge => {
                return (
                  <Avatar
                    key={badge.name}
                    size={"2extra-small"}
                    customLabel={badge.name}
                    backgroundColor={badge.background}
                    fontSize={"text-xs"}
                  />
                );
              })}
              {contact.is_messaging_cs && (
                <TailwindText
                  verticalAlign={"align-middle"}
                  borderRadius={"rounded-sm"}
                  margin={["ml-2"]}
                  whiteSpace={"whitespace-nowrap"}
                  padding={["p-1"]}
                  backgroundColor={"bg-yellow-300"}
                  fontSize={"text-xs"}>
                  Admin
                </TailwindText>
              )}
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default inject("messageStore")(observer(MessageCenterContact));
