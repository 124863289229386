import React from "react";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import Avatar from "library/components/avatar";
import TailwindFlex from "library/components/_tailwind/flex";
import { TailwindBoxProps } from "library/components/_tailwind/box";
import { useIntl } from "react-intl";

type Props = {
  conversation: MessageCenterConversationDetails;
  modelProfileImage: string;
  buttonChildren?: React.ReactNode;
  text?: string;
  boxProps?: TailwindBoxProps;
};

const MessageCenterModelNameBar: React.ComponentType<Props> = ({
  conversation,
  modelProfileImage,
  buttonChildren,
  text,
  boxProps,
}) => {
  const intl = useIntl();
  return (
    <TailwindFlex
      className={["MessageCenterModelNameBar"]}
      flexDirection={"flex-row"}
      backgroundColor='bg-highlight-color'
      gap={"gap-2.5"}
      padding={["p-4"]}
      {...boxProps}>
      <TailwindFlex
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        width={"w-auto"}>
        <Avatar
          photoURL={modelProfileImage}
          size={"small"}
          username={conversation?.participants?.[0]?.username}
          randomBackgroundColor={true}
        />
      </TailwindFlex>
      <TailwindFlex
        flexDirection={"flex-col"}
        alignItems={"items-start"}
        justifyContent={"justify-center"}>
        <TailwindFlex
          className={["MessageCenterModelNameBar__name"]}
          fontSize={"text-lg"}
          fontWeight='font-bold'>
          {conversation?.is_cs_convo ? (intl.formatMessage({id: 'liveChatSupport.select.modelSupport'})) : conversation?.participants?.[0]?.username}
        </TailwindFlex>
        {text ? (
          <TailwindFlex
            className={["MessageCenterModelNameBar__subtext"]}
            fontSize={"text-xs"}
            textColor={"text-gray-500"}>
            {text}
          </TailwindFlex>
        ) : null}
      </TailwindFlex>
      <TailwindFlex
        flexDirection={"flex-row"}
        alignItems={"items-center"}
        justifyContent='justify-end'
        flexGrow={"flex-grow"}>
        {buttonChildren}
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default MessageCenterModelNameBar;
