import React, { useCallback, useEffect, useMemo, useRef } from "react";
import TailwindFlex from "library/components/_tailwind/flex";

import TailwindButton from "library/components/_tailwind/button";
import MessageCenterModelNameBar from "../messages-model-name-bar";
import { inject, observer } from "mobx-react";
import MessageStore from "../stores/messages/MessageStore";
import ModalStore from "library/core/stores/modal";
import "./styles.scss";
import LanguageStore from "library/core/stores/language/LanguageStore";
import MCSharedMediaModalTabBar from "./tab-bar";
import MCSharedMediaModalList from "./media-list";
import { MC_CS_AGENT_PROFILE_IMG } from "../stores/messages/consts";

type Props = {
  isMobileDevice?: boolean;
  messageStore?: MessageStore;
  modalStore?: ModalStore;
  languageStore?: LanguageStore;
};

const SharedMediaModal: React.ComponentType<Props> = ({
  isMobileDevice = false,
  messageStore,
  modalStore,
  languageStore,
}) => {
  const {
    activeConversation,
    activeConversationAttachments,
    getConversationAttachments,
    clearConversationAttachments,
    activeMCSharedMediaModalTab,
    activeConversationAttachmentsLoading,
    nextAttachmentsUrl,
    activeConversationAttachmentsCount,
    isMCPhase2Active,
  } = messageStore!;
  const { closePrimaryModal } = modalStore!;
  const { intl } = languageStore!;
  const modalRef = useRef<HTMLDivElement>(null);

  const mediaCount = useMemo(() => {
    return activeConversationAttachmentsCount;
  }, [activeConversationAttachmentsCount]);

  const firstConversationParticipant = useMemo(
    () => activeConversation?.participants?.[0],
    [activeConversation]
  );

  const modelProfileImage = useMemo(
    () =>
      activeConversation && firstConversationParticipant
        ? activeConversation.is_cs_convo ? MC_CS_AGENT_PROFILE_IMG : firstConversationParticipant?.profile_picture
        : "",
    [activeConversation, firstConversationParticipant]
  );

  const handleNextPage = useCallback(() => {
    if (
      activeConversationAttachments?.length &&
      !activeConversationAttachmentsLoading &&
      nextAttachmentsUrl
    ) {
      getConversationAttachments();
    }
  }, [activeConversationAttachments, activeConversationAttachmentsLoading]);

  useEffect(() => {
    clearConversationAttachments();
    getConversationAttachments();
  }, [activeMCSharedMediaModalTab]);

  return (
    <TailwindFlex
      ref={modalRef}
      className={["SharedMediaModal"]}
      borderRadius={isMobileDevice ? "rounded-none" : "rounded-xl"}
      overflow={"overflow-hidden"}
      backgroundColor={"bg-primary-bg-color"}>
      <TailwindFlex
        display={"flex"}
        className={["SharedMediaModal__body"]}
        flexDirection={"flex-col"}>
        <TailwindFlex
          alignItems='items-center'
          flexDirection='flex-row'
          padding={["pl-6", "pr-6"]}
          className={["SharedMediaModal__header"]}
          width={"w-auto"}
          backgroundColor={"bg-highlight-color"}>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            width={"w-auto"}>
            <TailwindButton
              className={["SharedMediaModal__back-button"]}
              padding={["p-0", "pr-4"]}
              onClick={closePrimaryModal}
              alignSelf='self-center'
              rightIconProps={{
                name: "keyboard_arrow_left",
                fontSize: "text-2xl",
                textColor: "text-gray-400",
                style: {
                  width: "12px",
                },
              }}
            />
          </TailwindFlex>
          {activeConversation && isMCPhase2Active ? (
            <TailwindFlex
              className={["SharedMediaModal__model-name"]}
              flexGrow={"flex-grow"}>
              <MessageCenterModelNameBar
                conversation={activeConversation}
                modelProfileImage={modelProfileImage}
                text={intl.formatMessage({
                  id: "message-center.shared-conversation",
                  defaultMessage: "Shared Media",
                })}
                boxProps={{
                  padding: ["p-1.5"],
                }}
              />
            </TailwindFlex>
          ) : null}
        </TailwindFlex>
        <TailwindFlex width={"w-auto"}>
          <MCSharedMediaModalTabBar isMobile={isMobileDevice} />
        </TailwindFlex>
        <TailwindFlex position={"relative"}>
          {mediaCount === 0 &&
            (activeMCSharedMediaModalTab === "all" ||
              activeMCSharedMediaModalTab === "sold") && (
              <TailwindFlex
                width={"w-auto"}
                position={"absolute"}
                zIndex={"z-30"}
                margin={["m-5"]}
                inset={["top-0", "left-0"]}
                className={["SharedMediaModal__list-header"]}
                backgroundColor={"bg-primary-bg-color"}
                style={{
                  color: "#666666",
                }}>
                {intl.formatMessage({
                  id: `message-center.media-all-sold`,
                  defaultMessage:
                    "Currently there are no results available to display at this time.",
                })}
              </TailwindFlex>
            )}
          {mediaCount === 0 && activeMCSharedMediaModalTab === "for-sale" && (
            <TailwindFlex
              width={"w-auto"}
              position={"absolute"}
              zIndex={"z-30"}
              margin={["m-5"]}
              inset={["top-0", "left-0"]}
              className={["SharedMediaModal__list-header"]}
              backgroundColor={"bg-primary-bg-color"}
              style={{
                color: "#666666",
              }}>
              {intl.formatMessage({
                id: `message-center.media-for-sale`,
                defaultMessage:
                  "Currently there is no shared media available for sale at this time.  Make some headway by sending enticing photos to increase member engagement and earnings potential.",
              })}
            </TailwindFlex>
          )}
          {mediaCount > 0 && (
            <TailwindFlex
              width={"w-auto"}
              position={"absolute"}
              zIndex={"z-30"}
              margin={["m-5"]}
              inset={["top-0", "right-0"]}
              className={["SharedMediaModal__list-header"]}
              backgroundColor={"bg-primary-bg-color"}
              style={{
                color: "#666666",
              }}>
              {`${mediaCount} results`}
            </TailwindFlex>
          )}
        </TailwindFlex>

        <TailwindFlex
          flexGrow={"flex-grow"}
          overflow={"overflow-auto"}
          margin={["mt-5"]}
          padding={["pt-0", "pl-5", "pr-5"]}
          style={{
            minHeight: "200px",
          }}>
          <MCSharedMediaModalList
            media={activeConversationAttachments}
            isLoading={activeConversationAttachmentsLoading}
            isMCPhase2Active={isMCPhase2Active}
            onBottomInView={handleNextPage}
          />
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "messageStore",
  "modalStore",
  "languageStore"
)(observer(SharedMediaModal));
