import React, { useCallback, useMemo } from "react";
import { SharedMedia } from "./types";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindGrid from "library/components/_tailwind/grid";
import MessageCenterMessageThumbnails from "../messages-reader-message-thumbnails";
import { dateProcess } from "library/core/utility";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";
import { SharedMediaTypeEnum } from "../stores/messages/enum";

type MCSharedMediaModalListProps = {
  media: Array<SharedMedia>;
  isMCPhase2Active?: boolean;
  showSingleImg?: boolean;
  profileStore?: ProfileStore;
};

const MCSharedMediaModalGrid: React.FunctionComponent<MCSharedMediaModalListProps> =
  ({ media, isMCPhase2Active = false, showSingleImg, profileStore }) => {
    const { profile } = profileStore!;
    const { formatDate } = dateProcess;

    const isSenderCurrentUser = useCallback(
      id => {
        return id === profile?.["user_id"];
      },
      [profile]
    );

    const getMonth = date => {
      return formatDate(date, "MMMM, YYYY") || "";
    };

    const currentMonth = useMemo(() => {
      const today = new Date();
      return formatDate(today.toISOString(), "MMMM, YYYY") || "";
    }, []);

    const mediaNormalized = useMemo(() => {
      let lastMonth;
      const mediaArray: Array<SharedMedia> = [];
      const monthsArray: Array<string> = [];
      const results: Array<{
        month: string;
        media: Array<SharedMedia>;
      }> = [];

      media.forEach(m => {
        const currentMonth = getMonth(m.created_at);

        mediaArray.push({
          ...m,
          month: currentMonth,
        });

        if (currentMonth !== lastMonth) {
          monthsArray.push(currentMonth);
        }
        lastMonth = currentMonth;
      });

      monthsArray.forEach(m => {
        results.push({
          month: m,
          media: mediaArray.filter(md => md.month === m),
        });
      });

      return results;
    }, [media]);

    return (
      <TailwindFlex flexDirection={"flex-col"}>
        {mediaNormalized.map(m => {
          return (
            <>
              {m.month ? (
                <TailwindFlex
                  className={["MCSharedMediaModalGrid__date"]}
                  backgroundColor={"bg-primary-bg-color"}
                  position={"sticky"}
                  zIndex={"z-20"}
                  inset={["top-0"]}
                  padding={["pb-4"]}>
                  {m.month === currentMonth ? (
                    <TailwindTranslatedText
                      descriptor={{
                        id: `message-center.this-month`,
                      }}></TailwindTranslatedText>
                  ) : (
                    m.month
                  )}
                </TailwindFlex>
              ) : null}
              <TailwindGrid
                sm={{
                  cols: "grid-cols-2",
                }}
                cols={"grid-cols-4"}
                gap='gap-2'
                padding={["pb-4"]}>
                {m.media.map(md => (
                  <TailwindFlex
                    key={md.id}
                    textColor={
                      isSenderCurrentUser(md.sender)
                        ? "text-white"
                        : "text-main-color"
                    }>
                    <MessageCenterMessageThumbnails
                      isSenderCurrentUser={isSenderCurrentUser(md.sender)}
                      showGalleryVoiceImg={true}
                      backgroundColor={
                        isSenderCurrentUser(md.sender) ? "#84A3BA" : "#ffffff"
                      }
                      showSingleImg={showSingleImg}
                      message={{
                        id: md.id,
                        sender: {
                          id: `${md.sender}`,
                          top_admirer_rank: 0,
                          profile_picture: "",
                          username: "",
                          email: "",
                          first_name: "",
                          last_name: "",
                          is_bounty_member: false,
                          is_fanclub_member: false,
                          is_recent_visitor: false,
                          is_top_admirer: false,
                          is_top_spender: false,
                          is_referral_member: false,
                          can_receive_bulk_message: false,
                          can_receive_message: false,
                          profile_type: "",
                          is_messaging_cs: false
                        },
                        created_at: md.created_at,
                        status: md.status,
                        attachments_data: [
                          {
                            signed_url:
                              md.attachment_type ===
                                SharedMediaTypeEnum.Video && md.thumbnail_url
                                ? md.thumbnail_url
                                : md.signed_url,
                            price: md.price,
                            is_purchased: md.is_purchased,
                            attachment_type: md.attachment_type,
                            length: md.length ? parseFloat(md.length) : 0,
                          },
                        ],
                      }}
                      isMCPhase2Active={isMCPhase2Active}
                      disableContextMenu={true}
                    />
                  </TailwindFlex>
                ))}
              </TailwindGrid>
            </>
          );
        })}
      </TailwindFlex>
    );
  };

export default inject("profileStore")(observer(MCSharedMediaModalGrid));
