import React, { useCallback, useMemo, useRef, useState } from "react";
import { dateProcess } from "library/core/utility";
import { MessageCenterMessageDetails } from "./stores/messages/types";
import Avatar from "library/components/avatar";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import classnames from "classnames";
import tw, { TFontSize } from "library/styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import MessageCenterMessageThumbnails from "./messages-reader-message-thumbnails";
import sanitizeHtml from "sanitize-html";
import { toArray } from "react-emoji-render";
import ImageLoader from "library/components/image/loader";
import { SharedMediaTypeEnum } from "./stores/messages/enum";
import MessageTip from "./messages-tip";
import MessageGift from "./messages-gift";
import LoadingResourceSpinner from "./messages-loading-spinner";
import { MC_CS_AGENT_PROFILE_IMG } from "./stores/messages/consts";

type Props = {
  messageStore?: MessageStore;
  userName: string;
  message: MessageCenterMessageDetails;
  highlightedWord?: string;
  onCancelProgressClicked?: () => void;
};

const MessageCenterMessage: React.ComponentType<Props & WrappedComponentProps> =
  ({
    userName,
    message,
    highlightedWord,
    onCancelProgressClicked,
    messageStore,
    intl,
  }) => {
    const attachmentsRef = useRef<HTMLDivElement>(null);
    const [maxWidth, setMaxWidth] = useState<number>(0);
    const { messageFontSize, isMCPhase2Active } = messageStore!;
    const { getHumanReadableDate } = dateProcess;
    const isSenderCurrentUser = useMemo(() => {
      return message?.sender?.username === userName;
    }, [userName, message]);
    const maxWidthQueue: Array<number> = [];

    const messageDate = useMemo(
      () =>
        message?.created_at ? getHumanReadableDate(message?.created_at) : "",
      [message]
    );

    const isDeniedMessage = useMemo(
      () => !isSenderCurrentUser && message?.status == "DENIED",
      [message, isSenderCurrentUser]
    );

    const hasProcessingVideo = useMemo(
      () =>
        message?.attachments_data?.find(
          attachment =>
            attachment?.attachment_type === SharedMediaTypeEnum.Video &&
            !attachment?.thumbnail_url
        ),
      [message]
    );

    const isProcessingMessage = useMemo(() => {
      return (
        isSenderCurrentUser &&
        message?.status == "PROCESSING" &&
        hasProcessingVideo
      );
    }, [message, isSenderCurrentUser, hasProcessingVideo]);

    const hasAttachments = useMemo(
      () =>
        !isDeniedMessage &&
        ((message?.signed_urls && message?.signed_urls?.length > 0) ||
          (message?.attachments_data && message?.attachments_data?.length > 0)),
      [message, isDeniedMessage]
    );

    const noBorder = useMemo(
      () =>
        !isDeniedMessage &&
        message?.attachments_data &&
        message?.attachments_data?.length === 1 &&
        message?.attachments_data?.[0]?.attachment_type === "voice" &&
        message?.attachments_data?.[0]?.price &&
        message?.attachments_data?.[0]?.price > 0,
      [message, isDeniedMessage]
    );

    const hasTip = useMemo(() => {
      return (
        message?.data?.product === "tip_bio" && message?.data?.token_amount
      );
    }, [message]);

    const hasGift = useMemo(() => {
      return message?.data?.gift_url && message?.data?.token_amount;
    }, [message]);

    const deniedMsg = useMemo(() => {
      const msg = intl.formatMessage({
        id: "message-center.not-allowed",
        defaultMessage: "Message is not allowed",
      });
      return msg;
    }, []);

    const messageBoxStyle = useMemo(() => {
      const styles = {};

      if (maxWidth) {
        styles["maxWidth"] = `${maxWidth + 10}px`;
      }

      return styles;
    }, [maxWidth]);

    const containsVideo = useMemo(
      () =>
        message?.attachments_data?.some(
          attachment => attachment.attachment_type === SharedMediaTypeEnum.Video
        ),
      [message]
    );

    const containsAudio = useMemo(
      () =>
        message?.attachments_data?.some(
          attachment => attachment.attachment_type === "voice"
        ),
      [message]
    );

    const containsPendingAttachments = useMemo(
      () =>
        message?.attachments_data?.some(
          attachment => attachment.processing_data
        ),
      [message]
    );

    const formatMessagePreview = useCallback(
      (message: string) => {
        let conversation_msg = message || "";

        if (highlightedWord && conversation_msg) {
          const content = toArray(conversation_msg);
          const new_msg = content.reduce((previous, current) => {
            const searchRegEx = new RegExp(highlightedWord, "ig");
            const keywordMatch =
              typeof current === "string" ? current?.match(searchRegEx) : null;

            if (
              typeof current === "string" &&
              keywordMatch &&
              keywordMatch?.length
            ) {
              const current_html = current.replace(
                searchRegEx,
                `<span class="highlighted ${
                  isSenderCurrentUser ? "text-main-color" : "text-white"
                }" style="background:${
                  isSenderCurrentUser ? "#fff" : "#84a3ba"
                }">${keywordMatch[0]}</span>`
              );
              return previous + current_html;
            } else if (typeof current === "string") {
              return previous + current;
            }
            return previous + (current?.["props"]?.children || "");
          }, "");
          conversation_msg = new_msg?.toString() || conversation_msg;
        }

        return `${sanitizeHtml(conversation_msg, {
          allowedTags: ["span"],
          allowedAttributes: {
            span: ["class", "style"],
          },
          allowedStyles: {
            span: {
              // Match HEX and RGB
              background: [
                /^#(0x)?[0-9a-f]+$/i,
                /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
              ],
            },
          },
        })}`;
      },
      [highlightedWord]
    );

    const handleImageLoaded = () => {
      const attachmentsWidth = Math.max(
        maxWidth,
        attachmentsRef?.current?.querySelector?.(".MessagesReader__GalleryGrid")
          ?.clientWidth || 0
      );
      maxWidthQueue.push(attachmentsWidth);

      if (attachmentsWidth && message?.data?.message) {
        setMaxWidth(Math.max(...maxWidthQueue));
      } else {
        setMaxWidth(0);
      }
    };

    return isProcessingMessage ? null : (
      <TailwindFlex
        className={["MessagesReaderMessage"]}
        width={"w-auto"}
        textColor={
          isDeniedMessage
            ? "text-main-color"
            : isSenderCurrentUser
            ? "text-white"
            : "text-main-color"
        }
        justifyContent={isSenderCurrentUser ? "justify-end" : "justify-start"}
        margin={["mb-10"]}
        alignItems='items-end'>
        {!isSenderCurrentUser && (
          <TailwindFlex
            width={"w-auto"}
            margin={["mr-2"]}
            flexDirection={"flex-col"}
            alignItems={"items-center"}
            justifyContent={"justify-end"}>
              {console.log(message)}
            <Avatar
              photoURL={message?.sender?.is_messaging_cs ? MC_CS_AGENT_PROFILE_IMG : message?.sender?.profile_picture}
              username={message?.sender?.username}
              size={"small"}
              randomBackgroundColor={true}
            />
            {message?.sender?.first_name ? message?.sender?.first_name : null}
          </TailwindFlex>
        )}
        <TailwindFlex
          flexDirection='flex-col'
          maxWidth={"max-w-60"}
          width={"w-auto"}>
          {!isSenderCurrentUser && message?.has_reply === false && (
            <div className='relative group inine-flex'>
              <div className='replyIcon rounded-full z-50 right-0 -top-0.5'></div>
              <div className='tooltipBox p-2 rounded-md opacity-0 group-hover:opacity-100 z-40'>
                <div
                  className='text-left rounded-t-md text-white py-1'
                  style={{
                    padding: "5px 15px",
                    fontSize: "12px",
                    backgroundColor: "#ef951b",
                    fontWeight: "bold",
                  }}>
                  {intl.formatMessage({
                    id: `message-center.attention`,
                    defaultMessage: "ATTENTION",
                  })}
                </div>
                <div
                  className='bg-white rounded-b-md text-left'
                  style={{
                    fontSize: "12px",
                    padding: "15px 15px",
                  }}>
                  {intl.formatMessage({
                    id: `message-center.awaiting-response`,
                    defaultMessage: "Awaiting Response",
                  })}
                </div>
              </div>
            </div>
          )}
          <TailwindFlex
            width={"w-auto"}
            margin={["mb-0.5"]}
            padding={
              noBorder ? ["p-0"] : hasAttachments ? ["p-0.5"] : ["px-4", "py-2"]
            }
            borderRadius={
              isSenderCurrentUser
                ? ["rounded-bl-lg", "rounded-tr-lg", "rounded-tl-lg"]
                : ["rounded-br-lg", "rounded-tr-lg", "rounded-tl-lg"]
            }
            flexDirection={"flex-col"}
            position={"relative"}
            backgroundColor={"bg-white"}
            style={{
              marginLeft: isSenderCurrentUser ? "unset" : "6px",
              marginRight: isSenderCurrentUser ? "6px" : "unset",
              backgroundColor: isDeniedMessage
                ? "#ccc"
                : isSenderCurrentUser
                ? "#84a3ba"
                : "bg-white",
              ...messageBoxStyle,
            }}>
            {!isDeniedMessage && (
              <div ref={attachmentsRef}>
                {hasAttachments && hasProcessingVideo ? (
                  <TailwindFlex minWidth='min-w-40' padding={["p-0.5"]}>
                    <LoadingResourceSpinner />
                  </TailwindFlex>
                ) : !containsVideo &&
                  !containsAudio &&
                  containsPendingAttachments ? (
                  <ImageLoader />
                ) : hasTip ? (
                  <MessageTip amount={message?.data?.token_amount || 0} />
                ) : hasGift ? (
                  <MessageGift
                    src={message?.data?.gift_url || ""}
                    amount={message?.data?.token_amount || 0}
                  />
                ) : (
                  <MessageCenterMessageThumbnails
                    showGalleryVoiceImg={false}
                    showSingleImg={true}
                    messageFontSize={messageFontSize as TFontSize}
                    backgroundColor={
                      isSenderCurrentUser ? "#84A3BA" : "#ffffff"
                    }
                    message={message}
                    onImageLoad={handleImageLoaded}
                    isMCPhase2Active={isMCPhase2Active}
                    isSenderCurrentUser={isSenderCurrentUser}
                    onCancelProgressClicked={onCancelProgressClicked}
                    disableContextMenu={true}
                  />
                )}
              </div>
            )}

            <div
              className={`msg-container ${
                isDeniedMessage && "msg-container-denied"
              } ${isSenderCurrentUser ? `msg-container-right` : ""} ${
                noBorder ? "msg-container-slim" : ""
              }`}>
              <TailwindText
                padding={hasAttachments ? ["pl-2"] : []}
                wordBreak={"break-words"}
                whiteSpace={"whitespace-pre-wrap"}
                className={classnames(tw(messageFontSize as TFontSize))}
                dangerouslySetInnerHTML={{
                  __html: isDeniedMessage
                    ? deniedMsg
                    : formatMessagePreview(message?.data?.message || ""),
                }}
              />
            </div>
          </TailwindFlex>
          <TailwindFlex
            width={"w-full"}
            flexDirection={"flex-col"}
            alignItems={isSenderCurrentUser ? "items-end" : "items-start"}
            style={{
              fontSize: "10px",
            }}>
            <TailwindText
              margin={["mb-0"]}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}>
              {messageDate}
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>

        {isSenderCurrentUser && (
          <TailwindFlex
            width={"w-auto"}
            margin={["ml-2"]}
            flexDirection={"flex-col"}
            alignItems={"items-center"}></TailwindFlex>
        )}
      </TailwindFlex>
    );
  };

export default injectIntl(
  inject("messageStore")(observer(MessageCenterMessage))
);
