import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import {
  MessageAttachmentDetails,
  MessageCenterConversationDetails,
  MessagesAudioRecording,
} from "./stores/messages/types";

import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterInput from "./messages-reader-textarea";
import BroadcastChatFontSizePopover from "../broadcast/broadcast-chat/chat-font-size-popover";
import TailwindButton from "library/components/_tailwind/button";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import MessageStore from "./stores/messages/MessageStore";
import { TFontSize } from "library/styles/tailwind-classnames";
import TailwindBox from "library/components/_tailwind/box";
import useOutsideClick from "library/core/utility/hooks/useOutsideClick";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import FileUploadMessages from "library/components/file-upload-messages";
import TailwindIcon from "library/components/_tailwind/icon";
import ImgContainer from "./message-image-container";
import SnackbarStore from "library/core/stores/snackbar/SnackbarStore";
import { ISnackbarProps } from "library/core/stores/snackbar/interfaces";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { Icon } from "@material-ui/core";
import {
  ACCEPTED_ATTACHMENTS,
  ACCEPTED_IMAGES,
  MSG_CENTER_MAX_IMAGE_SIZE,
  MSG_CENTER_MAX_VIDEO_DURATION_IN_SECONDS,
  MSG_CENTER_MAX_VIDEO_SIZE,
  MSG_CENTER_MIN_VIDEO_DURATION_IN_SECONDS,
  MSG_CENTER_MIN_VIDEO_WIDTH,
} from "./stores/messages/consts";
import TailwindText from "library/components/_tailwind/text";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import StudioModelAccessRights from "common/studio-models-access-rights";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import ModalStore from "library/core/stores/modal";
import MessagesAttachmentPricingModal from "./messages-attachment-pricing-modal";
import TailwindPill from "library/components/_tailwind/pill";
import { formatter } from "library/core/utility";
// import axios from "axios";
import UploadingTile from "./messages-attachment-uploading-tile";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import MessagesAudioRecorder from "./messages-audio-recorder";
import SendMessageButton from "./messages-send-message-button";
import AudioMessageRecorderStore from "./stores/audio/AudioMessageRecorderStore";
import { SharedMediaTypeEnum } from "./stores/messages/enum";
import TailwindInputBase, {
  TailwindInputType,
} from "library/components/_tailwind/input-base";

type Props = {
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  profileStore?: ProfileStore;
  setMessage: (message: string) => void;
  message: string;
  sendMessage: (
    noticeBlock?: string,
    messageId?: string,
    onProgress?: (event: ProgressEvent) => void
  ) => void;
  conversation: MessageCenterConversationDetails | null;
  hasActiveConversation: boolean;
  snackbarStore?: SnackbarStore;
  modalStore?: ModalStore;
  pricingStore?: PricingStore;
  audioMessageRecorderStore?: AudioMessageRecorderStore;
};

export type imageType = {
  key: string;
  view_url?: string;
  preview_url: string;
  duration?: number;
  price?: number;
  presigned_post?: {
    form_data: Object;
    post_url: string;
  };
  file: File;
  is_transcoding?: boolean;
  uploaded?: boolean;
  thumbnail_key?: string | null;
  is_audio?: boolean;
};

const MessageCenterReaderFooter: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  messageStore,
  // layoutStore,
  snackbarStore,
  profileStore,
  audioMessageRecorderStore,
  modalStore,
  pricingStore,
  message,
  setMessage,
  sendMessage,
  hasActiveConversation,
  conversation,
  intl,
}) => {
  const {
    modelProducts,
    isLoading: loadingProducts,
    getPriceData,
  } = pricingStore!;
  const {
    isStudioModel,
    modelProfile: { access_rights },
  } = profileStore!;
  const { openPrimaryModal, closePrimaryModal } = modalStore!;
  const {
    messageFontSize,
    setMessageFontSize,
    signAttachments,
    uploadAttachments,
    setAttachImagesToBeSent,
    attachedImagesToBeSent,
    activeConversationId,
    setimageKeysTobeSubmitted,
    attachmentsTobeSubmitted,
    setAttachmentsTobeSubmitted,
    canCurrentMemberReceiveMsg,
    canTempMemberReceiveMsg,
    isMCPhase2Active,
    isMCPhase2MediaActive,
    cancelPendingAttachments,
    sendMessageCancelTokenSource,
    isSendingMessage,
    csResolveConvo,
    csUnResolveConvo,
  } = messageStore!;
  // const { windowWidth } = layoutStore!;
  const { autoSubmit } = audioMessageRecorderStore!;

  const messageInputRef = useRef<HTMLTextAreaElement>(null);

  const onSetMessageFontSize = (font: number | TFontSize) => {
    setMessageFontSize(font as TFontSize);
  };

  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const emojiBoxRef = useRef<HTMLDivElement>(null);

  const [images, setImages] = useState<imageType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentWidth, setAttachmentWidth] = useState<string>("0px");
  const [attachmentsUploading, setAttachmentsUploading] =
    useState<boolean>(false);
  const [attachmentsUploadingProgress, setAttachmentsUploadingProgress] =
    useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const { enqueueSnackbar } = snackbarStore!;

  useOutsideClick(emojiBoxRef, () => {
    setShowEmojiMenu(false);
  });

  const isSendButtonDisabled = useMemo(
    () =>
      (message.trim() === "" && attachedImagesToBeSent.length === 0) ||
      attachmentsUploading ||
      isSendingMessage,
    [message, attachedImagesToBeSent, attachmentsUploading, isSendingMessage]
  );

  const toastErrorAttachmentMsg = useMemo(() => {
    return {
      message: {
        id: "upload.error",
        default: isMCPhase2MediaActive ? (
          <span className='upload_toast_errorMsg'>
            <strong>
              <TailwindTranslatedText
                descriptor={{
                  id: `message-center.error.title`,
                  defaultMessage: "Upload Error",
                }}
              />
            </strong>
            <ul>
              <li>
                <TailwindTranslatedText
                  descriptor={{
                    id: `message-center.error.formats`,
                    defaultMessage:
                      "File formats allowed: jpeg, jpg, png, avi, mpeg, mpg, mov, mp4, mkv.",
                  }}
                />
              </li>
              <li>
                <TailwindTranslatedText
                  descriptor={{
                    id: `message-center.error.attachments_allowed`,
                    defaultMessage:
                      "Number of attachments allowed: 1 per message.",
                  }}
                />
              </li>
              <li>
                <TailwindTranslatedText
                  descriptor={{
                    id: `message-center.error.size_allowed`,
                    defaultMessage:
                      "File size allowed: 20MB maximum per image. 500MB maximum per video.",
                  }}
                />
              </li>
              <li>
                <TailwindTranslatedText
                  descriptor={{
                    id: `message-center.error.duration`,
                    defaultMessage:
                      "Videos must be between 30 seconds to 10 minutes long with a minimum width of {video_width} pixels.",
                  }}
                  values={{
                    video_width: MSG_CENTER_MIN_VIDEO_WIDTH,
                  }}
                />
              </li>
            </ul>
          </span>
        ) : (
          <span className='upload_toast_errorMsg'>
            <strong>Upload Error</strong>
            <ul>
              <li>File formats allowed: jpeg, jpg, and png.</li>
              <li>
                Number of images allowed: {isMCPhase2Active ? 1 : 5} per
                message.
              </li>
              <li>File size allowed: 20MB maximum per image.</li>
            </ul>
          </span>
        ),
      },
      variant: SnackbarVariants.ERROR,
      closeButton: true,
      options: {
        duration: 8000,
        className: "customUploadError",
        icon: <Icon fontSize='large'>cancelrounded</Icon>,
      },
    };
  }, [isMCPhase2Active, isMCPhase2MediaActive]);

  const canMembeReciveMsg = useMemo(() => {
    return (
      activeConversationId &&
      !canTempMemberReceiveMsg.includes(activeConversationId) &&
      canCurrentMemberReceiveMsg
    );
  }, [
    activeConversationId,
    canTempMemberReceiveMsg,
    canCurrentMemberReceiveMsg,
    message,
  ]);

  const defaultAttachmentPrice = useMemo(
    () => modelProducts?.conversation_message_attachment_price || 10,
    [modelProducts]
  );

  const onSelectEmoji = useCallback(
    event => {
      const emoji = event?.native;
      const _ref = messageInputRef as any;
      const inputEl: HTMLInputElement = _ref.current;
      const selectionStart = inputEl.selectionStart;
      let newMessage = message;
      if (selectionStart !== null) {
        const selectionEnd = inputEl.selectionEnd;
        const messageFirstPart = message.substr(0, selectionStart);

        if (selectionEnd !== null) {
          const messageSecondPart = message.substr(
            selectionStart,
            message.length
          );
          newMessage = messageFirstPart + emoji + messageSecondPart;
        } else {
          newMessage = message + emoji;
        }
      }
      inputEl.focus();
      setMessage(newMessage);
      setShowEmojiMenu(false);
    },
    [message, messageInputRef]
  );

  const isValidAttachment = (filesCount: number) => {
    if (
      (isMCPhase2Active && filesCount > 1) ||
      (!isMCPhase2Active && filesCount > 5)
    ) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };
  const isValidImage = (notValidfileType: boolean) => {
    if (notValidfileType) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };

  const isValidSize = (fileSize: number, fileType: string) => {
    if (
      (fileType.includes(SharedMediaTypeEnum.Image) &&
        fileSize > MSG_CENTER_MAX_IMAGE_SIZE) ||
      (fileType.includes(SharedMediaTypeEnum.Video) &&
        fileSize > MSG_CENTER_MAX_VIDEO_SIZE)
    ) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }

    return true;
  };

  const isValidDuration = (seconds: number) => {
    if (
      seconds < MSG_CENTER_MIN_VIDEO_DURATION_IN_SECONDS ||
      seconds > MSG_CENTER_MAX_VIDEO_DURATION_IN_SECONDS
    ) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };

  const isValidWidth = (width: number) => {
    if (width < MSG_CENTER_MIN_VIDEO_WIDTH) {
      enqueueSnackbar(toastErrorAttachmentMsg as unknown as ISnackbarProps);
      return false;
    }
    return true;
  };

  const getFilePreview = async (file: File) => {
    try {
      if (getFileType(file) == SharedMediaTypeEnum.Video) {
        return await getVideoPreviewThumbnail(file);
      }

      return await getImagePreviewThumbnail(file);
    } catch {
      return false;
    }
  };

  const getImagePreviewThumbnail = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = error => {
        reject(error);
      };
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const getVideoPreviewThumbnail = (file): Promise<string> => {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement("video");
      const videoSeekPosition = 5;
      videoPlayer.setAttribute("src", URL.createObjectURL(file));

      videoPlayer.onerror = () => {
        reject("error");
      };

      videoPlayer.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth || 250;
        canvas.height = videoPlayer.videoHeight || 200;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.fillStyle = "#000000";
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob(blob => {
            if (blob) {
              resolve(URL.createObjectURL(blob));
            } else {
              reject("failed to generate thumbnail");
            }
          }, "image/jpeg");
        } else {
          reject("failed to generate thumbnail");
        }
      };

      videoPlayer.onloadedmetadata = () => {
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime =
            videoPlayer.duration < videoSeekPosition ? 0 : videoSeekPosition; // use beginning of video if video is too short
        }, 200);
      };

      videoPlayer.load();
    });
  };

  const getVideoDuration = (
    file
  ): Promise<{ duration: number; width: number }> => {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));

      videoPlayer.onerror = error => {
        reject(error);
      };

      videoPlayer.onloadedmetadata = () => {
        resolve({
          duration: videoPlayer.duration,
          width: videoPlayer.videoWidth,
        });
      };

      videoPlayer.load();
    });
  };

  const cancelUploadingAttachment = () => {
    setAttachmentsUploadingProgress(0);
    setAttachmentsUploading(false);
    sendMessageCancelTokenSource.cancel();
    setAttachImagesToBeSent([]);
    setLoading(false);
  };

  const msgUploadImages = useCallback(
    async (files: File[]) => {
      // it will return 0 if the type is not supported
      if (files.length === 0) {
        isValidImage(true);
        return;
      }
      const filesCount = attachedImagesToBeSent.length + files.length;

      if (!isValidAttachment(filesCount)) return;
      files.forEach(async file => {
        const isVideo = getFileType(file) == SharedMediaTypeEnum.Video;
        const fileSize = (file.size / 1024 ** 2).toFixed(
          2
        ) as unknown as number; //max 20MB

        const formatsAccepted =
          isMCPhase2Active && isMCPhase2MediaActive
            ? ACCEPTED_ATTACHMENTS
            : ACCEPTED_IMAGES;

        if (
          !isValidSize(fileSize, file.type) ||
          !isValidImage(formatsAccepted.indexOf(file.type) === -1)
        ) {
          return;
        }

        let video_duration = 0;
        if (isVideo && activeConversationId) {
          if (file.type === "video/mp4") {
            const { duration, width } = await getVideoDuration(file);
            video_duration = duration;
            if (!isValidDuration(video_duration) || !isValidWidth(width)) {
              return;
            }
          }
        }

        setLoading(true);

        try {
          const preview_url = await getFilePreview(file);

          if (isMCPhase2Active) {
            setImages(prev => [
              ...prev,
              {
                key: file.name,
                preview_url: `${preview_url || ""}`,
                duration: video_duration,
                price: conversation?.participants[0]?.is_messaging_cs
                  ? 0
                  : defaultAttachmentPrice,
                file,
              },
            ]);
          } else {
            const { view_url, presigned_post, key } = await signAttachments(
              file.name,
              file.size
            );

            setImages(prev => [
              ...prev,
              {
                key,
                view_url,
                presigned_post,
                preview_url: `${preview_url || ""}`,
                duration: video_duration,
                price: conversation?.participants[0]?.is_messaging_cs
                  ? 0
                  : defaultAttachmentPrice,
                file,
              },
            ]);
          }
        } catch (error) {}

        setLoading(false);
      });
    },
    [
      isMCPhase2Active,
      isMCPhase2MediaActive,
      attachedImagesToBeSent,
      images,
      defaultAttachmentPrice,
    ]
  );

  const attachAudioRecording = useCallback(
    async (audio: MessagesAudioRecording) => {
      const file_extension =
        audio.type === "audio/mpeg" ? "mp3" : audio.type.split("/")?.[1];

      if (!file_extension) {
        return;
      }

      setLoading(true);
      const filename = `audio_${new Date().getTime()}.${file_extension}`;

      // set audio attachment to prepare for upload after send
      setImages([
        {
          is_audio: true,
          key: filename,
          preview_url: "",
          duration: audio.duration,
          price: audio.price || defaultAttachmentPrice || 0,
          file: new File([audio.file], filename, {
            type: audio.type,
          }),
        },
      ]);
      setLoading(false);
    },
    [images, attachedImagesToBeSent]
  );

  const sendAudioRecording = () => {
    sendMsg();
    setIsRecording(false);
  };

  useEffect(() => {
    if (autoSubmit && images?.[0]?.is_audio && attachmentsTobeSubmitted?.[0]) {
      sendAudioRecording();
    }
  }, [images, autoSubmit, attachmentsTobeSubmitted]);

  useEffect(() => {
    setImages([]);
    setAttachmentsUploadingProgress(0);
    setIsRecording(false);
  }, [activeConversationId]);

  useEffect(() => {
    const getImgSrc = images.map(img => img.view_url || img.key);
    const getImgKeys = images.map(img => img.key);
    setAttachImagesToBeSent(getImgSrc);
    setimageKeysTobeSubmitted(getImgKeys);

    setAttachmentsTobeSubmitted(
      images.map(img => {
        if (isMCPhase2Active && img.duration) {
          return {
            attachment_name: img.file.name,
            file: img.file,
            price: img.price,
            length: img.duration,
          } as MessageAttachmentDetails;
        } else if (!isMCPhase2Active) {
          return {
            attachment_name: img?.key,
            price: img?.price,
          } as MessageAttachmentDetails;
        }

        return {
          file: img.file,
          price: img.price,
        } as MessageAttachmentDetails;
      })
    );
  }, [isMCPhase2Active, images]);

  const updateAttachmentWidth = () => {
    setAttachmentWidth(`${messageInputRef?.current?.clientWidth || 0}px`);
  };

  useEffect(() => {
    updateAttachmentWidth();
    messageInputRef?.current?.focus();
  }, [messageInputRef?.current, attachedImagesToBeSent]);

  useEffect(() => {
    if (!loadingProducts) {
      getPriceData();
    }

    window.addEventListener("resize", updateAttachmentWidth);
    return () => window.removeEventListener("resize", updateAttachmentWidth);
  }, []);

  const removeImg = useCallback(
    key => {
      setImages(images.filter(img => img.key !== key));
    },
    [images]
  );

  const getFileType = (file: File): SharedMediaTypeEnum => {
    if (file?.type.includes("video")) {
      return SharedMediaTypeEnum.Video;
    } else if (file?.type.includes("audio")) {
      return SharedMediaTypeEnum.Audio;
    } else {
      return SharedMediaTypeEnum.Image;
    }
  };

  const handleSendMessageProgress = (event: ProgressEvent) => {
    const containsVideoAttachments = images.find(
      attachment => getFileType(attachment.file) === SharedMediaTypeEnum.Video
    );
    if (images.length && event.loaded && containsVideoAttachments) {
      const progress = Math.floor((event.loaded / event.total) * 100);
      setAttachmentsUploadingProgress(progress);
    }
  };

  const sendMsg = () => {
    if (!canMembeReciveMsg) return;
    const messageUID = `${activeConversationId || ""}-${new Date().getTime()}`;
    const imagesToUpload = [...images];

    try {
      imagesToUpload.forEach(attachment => {
        if (attachment?.file && attachment?.presigned_post) {
          uploadAttachments(
            messageUID,
            attachment?.key,
            attachment?.file,
            attachment?.presigned_post,
            {
              signed_url: attachment.preview_url,
              price: 0,
              attachment_type: getFileType(attachment?.file),
              is_purchased: false,
              length: attachment.duration,
              processing_data: {
                loaded: attachment?.uploaded ? attachment.file.size : 0,
                total: attachment.file.size,
              },
            }
          );
        }
      });
      sendMessage("", messageUID, handleSendMessageProgress);

      if (images[0]?.is_audio) {
        setImages([]);
      }
    } catch (error) {
      // something happened with upload, stop the send
      cancelPendingAttachments(messageUID);
    }
  };

  const openAttachmentPricingModal = useCallback(
    (image: imageType) => {
      if (!!access_rights?.is_pricing_settings_change_allowed) {
        openPrimaryModal(
          <MessagesAttachmentPricingModal
            image={image}
            isVideo={getFileType(image.file) === SharedMediaTypeEnum.Video}
            onSetPrice={price => {
              setImages(
                images.map(img => {
                  if (img.key === image.key) {
                    return {
                      ...img,
                      price: price,
                    };
                  }
                  return img;
                })
              );
              closePrimaryModal();
            }}
          />
        );
      }
    },
    [isStudioModel, access_rights, images]
  );

  useEffect(() => {
    if (!attachedImagesToBeSent.length && images.length) {
      setImages([]);
      setAttachmentsUploadingProgress(0);
    }
  }, [attachedImagesToBeSent]);

  return hasActiveConversation && !isRecording ? (
    <TailwindFlex
      flexDirection={"flex-col"}
      padding={
        isMCPhase2Active && isMCPhase2MediaActive ? ["px-4", "pb-5"] : ["py-2"]
      }>
      <TailwindFlex
        flexDirection={"flex-col"}
        borderRadius={
          isMCPhase2Active && isMCPhase2MediaActive
            ? "rounded-lg"
            : "rounded-none"
        }
        backgroundColor={
          isMCPhase2Active && isMCPhase2MediaActive
            ? "bg-primary-bg-color"
            : "bg-transparent"
        }
        borderWidth={["border"]}
        borderColor={
          message.length >= 500 ? "border-red-400" : "border-transparent"
        }>
        <TailwindFlex
          width='w-full'
          padding={
            attachedImagesToBeSent.length > 0
              ? ["pb-0", "pt-2"]
              : isMCPhase2Active && isMCPhase2MediaActive
              ? []
              : ["pb-4"]
          }>
          {canMembeReciveMsg ? (
            <TailwindFlex
              backgroundColor='bg-primary-bg-color'
              borderRadius={"rounded-lg"}
              flexDirection={"flex-col"}
              margin={["m-4"]}>
              <MessageCenterInput
                inputRef={messageInputRef}
                onChange={setMessage}
                value={message}
                placeholder={intl.formatMessage({
                  id: "message-center.type-your-message-here",
                  defaultMessage: "Type your message here",
                })}
                enterPressCallback={sendMsg}
                disabled={
                  !hasActiveConversation || loading || attachmentsUploading
                }
                loading={loading}
                boxProps={{
                  fontSize: "text-sm",
                  padding: ["p-0"],
                }}
                maxRows={attachedImagesToBeSent.length > 0 && !loading ? 2 : 3}
                maxLength={500}
              />
              {attachedImagesToBeSent.length > 0 && !loading && (
                <TailwindFlex
                  className={["MessagesReader__attachments"]}
                  margin={["mb-2", "mx-4"]}>
                  <TailwindBox
                    style={{
                      width: attachmentWidth,
                      paddingTop: "4px",
                    }}
                    position={"absolute"}
                    whiteSpace='whitespace-nowrap'
                    display={"block"}
                    overflow={["overflow-x-auto", "overflow-y-hidden"]}>
                    {isMCPhase2Active && attachmentsUploadingProgress ? (
                      <UploadingTile
                        remove={cancelUploadingAttachment}
                        progress={attachmentsUploadingProgress}
                      />
                    ) : (
                      images?.map(img => (
                        <ImgContainer
                          key={img.key}
                          removeImg={removeImg}
                          imgSrc={img.preview_url}
                          id={img.key}
                          isImageBackground={true}
                          isVideo={
                            img.file
                              ? getFileType(img.file) ===
                                SharedMediaTypeEnum.Video
                              : false
                          }
                          boxProps={{
                            position: "relative",
                            borderRadius: "rounded-md",
                          }}
                          topBar={
                            isMCPhase2Active && img.duration ? (
                              <TailwindFlex
                                justifyContent={"justify-start"}
                                alignItems={"items-center"}>
                                <TailwindPill
                                  className={[
                                    "MessagesReader__video-duration-pill",
                                  ]}
                                  size={"xxs"}
                                  textColor={"text-white"}
                                  leftIconProps={{
                                    name: "duration",
                                  }}>
                                  {formatter.formatSeconds(
                                    img?.duration,
                                    "MM:SS"
                                  )}
                                </TailwindPill>
                              </TailwindFlex>
                            ) : null
                          }
                          bottomBar={
                            isMCPhase2Active ? (
                              <TailwindFlex
                                textColor={"text-white"}
                                backgroundImage={"bg-gradient-to-b"}
                                gradientColorStops={[
                                  "from-transparent",
                                  "from-transparent",
                                  "to-black",
                                ]}
                                borderRadius={"rounded-md"}
                                padding={["p-2"]}
                                onClick={() => {
                                  openAttachmentPricingModal(img);
                                }}>
                                <TailwindFlex
                                  justifyContent={"justify-start"}
                                  alignItems={"items-center"}>
                                  <TailwindIcon
                                    display={"inline-flex"}
                                    alignItems={"items-center"}
                                    name={"lock"}
                                    fontSize={"text-sm"}
                                  />
                                  <TailwindTranslatedText
                                    margin={["ml-1.5"]}
                                    fontSize={"text-sm"}
                                    descriptor={{
                                      id: "message-center.attachment-price-label",
                                      defaultMessage: "{token_amount} tokens",
                                    }}
                                    values={{
                                      token_amount: img.price,
                                    }}
                                  />
                                </TailwindFlex>
                                <StudioModelAccessRights
                                  isIndividualModel={!isStudioModel}
                                  shouldShow={
                                    !!access_rights?.is_pricing_settings_change_allowed
                                  }
                                  hideContent={true}
                                  type={"pricing"}>
                                  <TailwindFlex justifyContent={"justify-end"}>
                                    <TailwindIcon
                                      display={"inline-flex"}
                                      alignItems={"items-center"}
                                      name={"create"}
                                      fontSize={"text-base"}
                                    />
                                  </TailwindFlex>
                                </StudioModelAccessRights>
                              </TailwindFlex>
                            ) : null
                          }
                        />
                      ))
                    )}
                  </TailwindBox>
                </TailwindFlex>
              )}
            </TailwindFlex>
          ) : (
            <TailwindBox
              display='flex'
              borderStyle='border-solid'
              borderColor='border-gray-400'
              borderWidth={["border"]}
              borderRadius={["rounded-md"]}
              margin={["mx-5", "my-2"]}
              padding={["px-5", "py-3"]}
              style={{
                minHeight: "65px",
              }}
              width='w-full'>
              <TailwindBox display='flex' alignItems='items-baseline'>
                <TailwindIcon
                  name={"!"}
                  textColor='text-gray-400'
                  margin={["mr-3"]}
                  className={"exclamationMark-icon"}
                />
                <TailwindText
                  fontSize='text-lg'
                  fontStyle='italic'
                  textColor='text-gray-400'>
                  {
                    "To prevent spamming please wait 24 hours and try again or until the member has responded."
                  }
                </TailwindText>
              </TailwindBox>
            </TailwindBox>
          )}
        </TailwindFlex>
        <TailwindFlex
          padding={["pb-2", "px-4"]}
          space='space-x-3'
          alignItems={"items-end"}
          justifyContent={"justify-end"}>
          <TailwindFlex
            className={["MessagesReaderFooter"]}
            justifyContent={"justify-start"}
            alignItems={"items-start"}
            space='space-x-2'>
            <TailwindBox>
              <TailwindButton
                fullWidth={false}
                width={"w-10"}
                height={"h-10"}
                padding={["p-0"]}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                rounded={true}
                opacity={!canMembeReciveMsg ? "opacity-30" : "opacity-100"}
                cursor={
                  !canMembeReciveMsg ? "cursor-not-allowed" : "cursor-pointer"
                }
                rightIconProps={{
                  fontSize: "text-xl",
                  name: CustomIconName.emoji,
                }}
                style={{
                  backgroundColor: "#84a3ba",
                }}
                onClick={() =>
                  setShowEmojiMenu(!canMembeReciveMsg ? false : !showEmojiMenu)
                }
              />
              <TailwindBox
                ref={emojiBoxRef}
                inset={["bottom-20"]}
                position={"absolute"}
                zIndex={"z-100"}
                display={showEmojiMenu ? "block" : "hidden"}>
                <Picker
                  skinTonePosition={"search"}
                  data={data}
                  onEmojiSelect={onSelectEmoji}
                  icons={"solid"}
                  theme={"auto"}
                  noCountryFlags={true}
                />
              </TailwindBox>
            </TailwindBox>
            <BroadcastChatFontSizePopover
              fontSize={messageFontSize as TFontSize}
              onSetFont={onSetMessageFontSize}>
              <TailwindButton
                fullWidth={false}
                style={{
                  backgroundColor: "#84a3ba",
                }}
                borderColor={"border-main-color"}
                rounded={true}
                padding={["p-0"]}
                width={"w-10"}
                height={"h-10"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                rightIconProps={{
                  fontSize: "text-base",
                  name: CustomIconName["text-size-icon"],
                }}
              />
            </BroadcastChatFontSizePopover>

            <FileUploadMessages
              textColor={"text-white"}
              style={{
                backgroundColor: "#84a3ba",
              }}
              borderColor={"border-white"}
              shape={"circle"}
              customIcon={<TailwindIcon name={CustomIconName["upload-icon"]} />}
              width={"w-10"}
              height={"h-10"}
              dropzoneProps={{
                disabled: attachmentsUploading,
                accept:
                  isMCPhase2Active && isMCPhase2MediaActive
                    ? ACCEPTED_ATTACHMENTS
                    : ACCEPTED_IMAGES,
                onDrop: msgUploadImages,
                maxFiles: 20,
                multiple: false,
              }}
              opacity={!canMembeReciveMsg ? "opacity-30" : "opacity-100"}
              acceptedText={"Photo Uploaded"}
              disable={!canMembeReciveMsg}
              cursor={
                !canMembeReciveMsg ? "cursor-not-allowed" : "cursor-pointer"
              }
            />

            {conversation?.participants[0]?.is_messaging_cs ? (
              <>
                <TailwindFlex
                  alignItems='items-center'
                  justifyContent='justify-start'
                  width={"w-auto"}
                  height={"h-10"}>
                  <TailwindInputBase
                    name={"checkbox"}
                    type={TailwindInputType.checkbox}
                    cursor={"cursor-pointer"}
                    width={"w-6"}
                    height={"h-6"}
                    psuedoClasses={["focus:outline-none"]}
                    wrapperProps={{
                      display: "flex",
                      alignItems: "items-center",
                      justifyContent: "justify-center",
                      margin: ["ml-1.5", "mr-2.5"],
                    }}
                    onChange={() => {
                      if (conversation?.status === "closed") {
                        csUnResolveConvo(conversation?.id);
                      } else {
                        csResolveConvo(conversation?.id);
                      }
                    }}
                    checked={conversation?.status === "closed"}
                  />
                  <TailwindText
                    fontWeight='font-semibold'
                    style={{
                      color: "#84a3ba",
                    }}>
                    Closed
                  </TailwindText>
                </TailwindFlex>
              </>
            ) : null}

            {isMCPhase2Active && isMCPhase2MediaActive ? (
              <>
                <TailwindBox>
                  <TailwindButton
                    alignItems={"items-center"}
                    justifyContent={"justify-center"}
                    fullWidth={false}
                    width={"w-10"}
                    height={"h-10"}
                    padding={["p-0"]}
                    rounded={true}
                    opacity={!canMembeReciveMsg ? "opacity-30" : "opacity-100"}
                    cursor={
                      !canMembeReciveMsg
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }
                    style={{
                      backgroundColor: "#84a3ba",
                    }}
                    rightIconProps={{
                      fontSize: "text-xl",
                      name: CustomIconName["mic-outline"],
                    }}
                    onClick={() => {
                      setIsRecording(true);
                    }}
                  />
                </TailwindBox>
              </>
            ) : null}
          </TailwindFlex>

          {isMCPhase2Active ? (
            <TailwindFlex
              gap='gap-5'
              justifyContent='justify-end'
              flexDirection='flex-row'
              opacity={
                loading || !canMembeReciveMsg || isSendButtonDisabled
                  ? "opacity-70"
                  : "opacity-100"
              }>
              <TailwindText
                display={"flex"}
                justifyContent='justify-center'
                alignItems='items-center'
                flexDirection='flex-col'
                fontSize='text-sm'
                style={{
                  color: message.length === 500 ? "#FF0000" : "#999999",
                }}>
                {message.length}/500
              </TailwindText>
              <SendMessageButton
                onSubmit={sendMsg}
                disabled={loading || !canMembeReciveMsg || isSendButtonDisabled}
              />
            </TailwindFlex>
          ) : (
            <TailwindBox
              opacity={
                loading || !canMembeReciveMsg || isSendButtonDisabled
                  ? "opacity-70"
                  : "opacity-100"
              }>
              <TailwindButton
                onClick={() => sendMsg()}
                fullWidth={false}
                backgroundColor={"bg-modal-button-color"}
                borderRadius={"rounded"}
                textColor={"text-white"}
                width={"w-auto"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["px-6"]}
                disabled={loading || !canMembeReciveMsg || isSendButtonDisabled}
                settings={true}
                disabledProps={{
                  backgroundColor: "bg-modal-button-color",
                  textColor: "text-white",
                }}>
                {intl.formatMessage({
                  id: "common.send",
                  defaultMessage: "Send",
                })}
              </TailwindButton>
            </TailwindBox>
          )}
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  ) : hasActiveConversation && isRecording ? (
    <MessagesAudioRecorder
      onPricingClick={() => {
        openAttachmentPricingModal(images?.[0]);
      }}
      onRemove={() => {
        const audioKey = images?.[0]?.key;
        if (audioKey) {
          removeImg(audioKey);
        }

        setIsRecording(false);
      }}
      onRecordingSaved={attachAudioRecording}
      onSubmit={sendAudioRecording}
      price={images?.[0]?.price || defaultAttachmentPrice}
    />
  ) : null;
};

export default injectIntl(
  inject(
    "messageStore",
    "snackbarStore",
    "layoutStore",
    "profileStore",
    "modalStore",
    "pricingStore",
    "audioMessageRecorderStore"
  )(observer(MessageCenterReaderFooter))
);
