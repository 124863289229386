import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import MessageCenterConversation from "./messages-navigator-conversation";
import MessageCenterNewsAndPolicy from "./messages-navigator-news-and-policy";
import { Icon } from "@material-ui/core";
import { injectIntl, WrappedComponentProps } from "react-intl";
import LoadingResourceSpinner from "./messages-loading-spinner";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import {
  MessageCenterContactDetails,
  MessageCenterConversationDetails,
  MessageCenterMessageDetails,
} from "./stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import { MessageTabEnum } from "./stores/messages/consts";
import { userRole } from "library/core/stores/consts";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import ModalStore from "library/core/stores/modal";
import MessageCenterDeleteModal from "./messages-delete-modal";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { AppCommonRouteKey } from "core/stores/route/enums";
import { useHistory } from "react-router-dom";
import RouteStore from "core/stores/route/RouteStore";

type Props = {
  isMobile: boolean;
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  modalStore?: ModalStore;
  profileStore?: ProfileStore;
  routeStore?: RouteStore;
  onScrollUp?: () => void;
  onScrollDown?: () => void;
  onScrollReset?: () => void;
  onMultipleMatchesViewShown?: (
    searchedMessages: MessageCenterConversationDetails[]
  ) => void;
  searchedMessagesView: boolean;
  setSearchedMessagesView: (is_active: boolean) => void;
  type?: MessageTabEnum;
};

const MessageCenterConversationList: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  messageStore,
  modalStore,
  profileStore,
  routeStore,
  isMobile,
  onScrollUp,
  onScrollDown,
  onScrollReset,
  onMultipleMatchesViewShown,
  searchedMessagesView,
  setSearchedMessagesView,
  intl,
  type = MessageTabEnum.MESSAGE,
}) => {
  const {
    conversations,
    sortedConversations,
    selectConversations,
    selectAllConversations,
    unSelectAllConversations,
    selectedConversations,
    readConversation,
    hasMoreConversations: hasMore,
    conversationsSearchTerm,
    setConversationsSearchTerm,
    isSearchingConversations: isSearching,
    isConversationsLoading: isLoading,
    isConversationsLoadingMore: isLoadingMore,
    getConversations,
    activeConversationId,
    newsAndPolicies,
    getPublicNotices,
    readNewsAndPolicy,
    isSearchingView,
    setCanCurrentMemberReceiveMsg,
    isBulkMessageView,
    sendMessageToCS
  } = messageStore!;
  const history = useHistory();
  const { openPrimaryModal } = modalStore!;
  const { profile } = profileStore!;
  const { currentRoutePathname, getSiteRouteURL } = routeStore!;

  const [searchedMessages, setSearchedMessages] = useState<
    MessageCenterConversationDetails[]
  >([]);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  let lastScrollTop = 0;

  const loadMoreConversations = useCallback(() => {
    if (!isLoading) {
      getConversations(true);
    }
  }, [isLoading, getConversations]);

  const onConversationClick = useCallback(
    (conversation: MessageCenterConversationDetails) => {
      if (conversation.id) {
        if (
          isMobile &&
          currentRoutePathname ===
            `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
              AppCommonRouteKey.searchMessages
            )}`
        ) {
          history.replace(
            `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
              AppCommonRouteKey.compose
            )}`
          );
        } else if (isMobile) {
          history.push(
            `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
              AppCommonRouteKey.compose
            )}`
          );
        }

        if (
          (conversationsSearchTerm || searchedMessagesView) &&
          conversation?.searched_messages?.[0]
        ) {
          readConversation(
            conversation?.id,
            conversation?.searched_messages?.[0].created_at
          );
          setSearchedMessagesView?.(false);
          setSearchedMessages([]);
          setConversationsSearchTerm("");
        } else {
          if(conversation.id === 'cs-convo') {
            sendMessageToCS()
          } else {
            readConversation(conversation.id);
          }
        }
      }
    },
    [searchedMessagesView, conversations, isMobile]
  );

  const onNewsAndPolicyClick = useCallback(
    (article: MessageCenterConversationDetails) => {
      if (article?.id) {
        readNewsAndPolicy(article?.id);
      }
    },
    []
  );

  const hasConversations = useMemo(
    () => Array.isArray(conversations) && conversations.length > 0,
    [conversations]
  );
  const hasNewsAndPolicies = useMemo(
    () => Array.isArray(newsAndPolicies) && newsAndPolicies.length > 0,
    [newsAndPolicies]
  );

  const isMobileAndSearching = useMemo(
    () => isSearchingView && isMobile,
    [isSearchingView, isMobile]
  );

  const removeScrollListener = () => {
    scrollContainerRef?.current?.removeEventListener(
      "scroll",
      _.throttle(detectScrollDirection, 200)
    );
  };

  const attachScrollListener = () => {
    scrollContainerRef?.current?.addEventListener(
      "scroll",
      _.throttle(detectScrollDirection, 200)
    );
  };

  const detectScrollDirection = () => {
    if (scrollContainerRef.current?.scrollTop) {
      if (scrollContainerRef.current?.scrollTop <= 0) {
        onScrollReset?.();
      } else if (
        scrollContainerRef.current?.scrollTop >=
        scrollContainerRef.current?.scrollHeight -
          scrollContainerRef.current?.clientHeight
      ) {
        onScrollDown?.();
      } else if (scrollContainerRef.current?.scrollTop > lastScrollTop) {
        onScrollDown?.();
      } else {
        onScrollUp?.();
      }

      if (scrollContainerRef.current?.scrollTop <= 0) {
        lastScrollTop = 0;
      } else {
        lastScrollTop = scrollContainerRef.current?.scrollTop;
      }
    } else {
      lastScrollTop = 0;
    }
  };

  useEffect(() => {
    if (!searchedMessagesView) {
      setSearchedMessages([]);
      onMultipleMatchesViewShown?.([]);
    } else {
      onMultipleMatchesViewShown?.(searchedMessages);
    }
  }, [searchedMessagesView]);

  useEffect(() => {
    getPublicNotices();
    attachScrollListener();

    return () => {
      removeScrollListener();
    };
  }, []);

  useEffect(() => {
    if (
      selectAllChecked &&
      selectedConversations.length < conversations.length
    ) {
      setSelectAllChecked(false);
    } else if (
      !selectAllChecked &&
      selectedConversations.length >= conversations.length
    ) {
      setSelectAllChecked(true);
    }
  }, [selectedConversations, conversations]);

  return (
    <TailwindFlex
      flexDirection='flex-col'
      height={"h-full"}
      justifyContent={
        (isLoading || isSearching) && !isLoadingMore
          ? "justify-center"
          : "justify-start"
      }
      alignItems={
        (isLoading || isSearching) && !isLoadingMore
          ? "items-center"
          : "items-start"
      }
      backgroundColor='bg-quaternary-bg-color'>
      {selectedConversations && selectedConversations.length > 0 && (
        <TailwindFlex
          padding={["py-1"]}
          borderWidth={["border-t-2"]}
          borderColor={"border-primary-bg-color"}
          flexDirection={"flex-row"}
          style={{
            paddingRight: "15px",
          }}
          fontSize='text-sm'>
          <TailwindFlex
            width={"w-auto"}
            justifyContent='justify-center'
            alignItems='items-center'>
            <TailwindCheckboxRadio
              borderColor='border-main-color'
              boxProps={{
                flexDirection: "flex-col",
                textAlign: "text-center",
                style: {
                  width: "40px",
                  margin: "0 15px",
                },
              }}
              onChange={() => {
                if (selectAllChecked) {
                  setSelectAllChecked(false);
                  unSelectAllConversations();
                } else {
                  setSelectAllChecked(true);
                  selectAllConversations();
                }
              }}
              isChecked={selectAllChecked}
              type={TailwindInputType.radio}
              labelProps={{
                margin: ["m-0"],
                textColor: "text-main-color",
                lineHeight: "leading-snug",
                fontSize: "text-sm",
              }}
              label={"All"}
            />
          </TailwindFlex>
          <TailwindFlex alignItems='items-center'>
            {selectedConversations.length} Selected
          </TailwindFlex>
          <TailwindFlex
            width={"w-auto"}
            alignItems='items-center'
            justifyContent='justify-center'>
            <TailwindTooltip
              content={intl.formatMessage({
                id: "verbiage.delete",
                defaultMessage: "Delete",
              })}>
              <TailwindButton
                backgroundColor='bg-tertiary-bg-color'
                padding={["p-0"]}
                rounded={true}
                onClick={() => {
                  openPrimaryModal(<MessageCenterDeleteModal />, {
                    showNativeCloseButton: false,
                  });
                }}
                justifyContent='justify-center'
                alignItems='items-center'
                rightIconProps={{
                  name: "delete",
                  fontSize: "text-3xl",
                  textColor: "text-secondary-color",
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </TailwindTooltip>
          </TailwindFlex>
        </TailwindFlex>
      )}
      {!hasConversations &&
        type === MessageTabEnum.MESSAGE &&
        !isLoading &&
        !isLoadingMore &&
        userRole !== "studio" && (
          <TailwindFlex
            alignItems={"items-center"}
            height={"h-full"}
            justifyContent={"justify-center"}>
            {conversationsSearchTerm ? (
              intl.formatMessage({
                id: "message-center.no-results-found",
                defaultMessage: "No results found",
              })
            ) : (
              <>
                {intl.formatMessage({
                  id: "message-center.start-your-conversation-by",
                  defaultMessage: "Start your conversation by",
                })}
                &nbsp;
                {!isMobile
                  ? intl
                      .formatMessage({
                        id: "verbiage.clicking",
                        defaultMessage: "Clicking",
                      })
                      .toLocaleLowerCase()
                  : intl
                      .formatMessage({
                        id: "verbiage.tapping",
                        defaultMessage: "Tapping",
                      })
                      .toLocaleLowerCase()}
                &nbsp;
                <Icon>create</Icon>
              </>
            )}
          </TailwindFlex>
        )}

      <TailwindFlex
        ref={scrollContainerRef}
        flexDirection={"flex-col"}
        overflow={["overflow-y-auto", "overflow-x-hidden"]}
        maxHeight={"max-h-full"}
        backgroundColor={"bg-quaternary-bg-color"}
        padding={isMobileAndSearching ? ["pb-24"] : ["p-0"]}>
        {(isLoading || isSearching) && !isLoadingMore && (
          <TailwindFlex
            height={"h-full"}
            justifyContent='justify-center'
            alignItems='items-center'>
            <LoadingResourceSpinner
              name={intl.formatMessage({
                id: "verbiage.conversations",
                defaultMessage: "Conversations",
              })}
            />
          </TailwindFlex>
        )}
        {!isLoading &&
          hasNewsAndPolicies &&
          type === MessageTabEnum.POLICY &&
          newsAndPolicies.map(item => (
            <MessageCenterNewsAndPolicy
              key={item.id}
              isActive={activeConversationId === item.id}
              notice={item}
              readNotice={onNewsAndPolicyClick}
            />
          ))}
        {!isLoading && hasConversations && type === MessageTabEnum.MESSAGE
          ? searchedMessagesView
            ? searchedMessages.map(
                conversation =>
                  (conversation?.last_message || conversation?.is_cs_convo) && (
                    <MessageCenterConversation
                      key={conversation.id}
                      conversation={conversation}
                      readConversation={() => onConversationClick(conversation)}
                      conversationsSearchTerm={conversationsSearchTerm}
                      isMobileAndSearching={isMobileAndSearching}
                      canReceiveMessage={conversation.can_receive_message}
                      setCanCurrentMemberReceiveMsg={
                        setCanCurrentMemberReceiveMsg
                      }
                      userName={profile?.username}
                      showBorder={true}
                    />
                  )
              )
            : sortedConversations.map(conversation => (
                
                  <MessageCenterConversation
                    key={conversation.id}
                    isActive={activeConversationId === conversation?.id}
                    isSelected={selectedConversations.includes(conversation?.id)}
                    conversation={conversation}
                    readConversation={() => onConversationClick(conversation)}
                    selectConversation={selectConversations}
                    onMultipleMatchesClick={conversation => {
                      const messages: MessageCenterConversationDetails[] = [];
                      const model = conversation?.participants?.find(
                        user => user?.profile_type === "model"
                      );

                      if (conversation.searched_messages) {
                        conversation.searched_messages.forEach(msg => {
                          const senderId = msg.sender.toString();
                          const searchedMessage = {
                            ...msg,
                            sender: {
                              id: senderId,
                              profile_picture: "",
                              username: "",
                              email: "",
                              first_name: "",
                              last_name: "",
                              profile_type:
                                senderId === model?.id ? "model" : "member",
                              top_admirer_rank: 0,
                              is_bounty_member: false,
                              is_fanclub_member: false,
                              is_recent_visitor: false,
                              is_top_admirer: false,
                              is_top_spender: false,
                              is_referral_member: false,
                              can_receive_bulk_message: false,
                              can_receive_message: false,
                            } as MessageCenterContactDetails,
                          };
                          messages.push({
                            ...conversation,
                            ...{
                              searched_messages: [
                                searchedMessage as MessageCenterMessageDetails,
                              ],
                            },
                          });
                        });
                      }

                      setSearchedMessages(messages);
                      setSearchedMessagesView?.(true);
                    }}
                    conversationsSearchTerm={conversationsSearchTerm}
                    isMobileAndSearching={isMobileAndSearching}
                    canReceiveMessage={conversation.can_receive_message}
                    setCanCurrentMemberReceiveMsg={
                      setCanCurrentMemberReceiveMsg
                    }
                    isBulkMessageView={isBulkMessageView}
                    userName={profile?.username}
                    showBorder={true}
                  />
                
              ))
          : null}
        {/* {!isLoading &&
          hasConversations &&
          type === MessageTabEnum.MESSAGE &&
          sortedConversations.map(conversation => (
            <MessageCenterConversation
              key={conversation.id}
              isActive={activeConversationId === conversation.id}
              isSelected={selectedConversations.includes(conversation.id)}
              conversation={conversation}
              readConversation={() => onConversationClick(conversation)}
              selectConversation={selectConversations}
              conversationsSearchTerm={conversationsSearchTerm}
              isMobileAndSearching={isMobileAndSearching}
              canReceiveMessage={conversation.can_receive_message}
              setCanCurrentMemberReceiveMsg={setCanCurrentMemberReceiveMsg}
              isBulkMessageView={isBulkMessageView}
              userName={profile?.username}
            />
          ))} */}
        {!hasNewsAndPolicies &&
          !hasConversations &&
          !isLoading &&
          !isLoadingMore &&
          userRole === "studio" && (
            <TailwindFlex
              alignItems={"items-center"}
              height={"h-full"}
              justifyContent={"justify-center"}>
              {intl.formatMessage({
                id: "message-center.check-back-soon-latest-news",
                defaultMessage:
                  "Check soon for the latests news and policies announcements",
              })}
            </TailwindFlex>
          )}
        {hasMore && !isLoading && !isLoadingMore && (
          <TailwindFlex
            alignItems={"items-center"}
            height={"h-full"}
            justifyContent={"justify-center"}>
            <TailwindButton
              justifyContent={"justify-center"}
              onClick={loadMoreConversations}
              fullWidth>
              {intl.formatMessage({
                id: "common.load-more",
                defaultMessage: "Load More",
              })}
            </TailwindButton>
          </TailwindFlex>
        )}
        {isLoadingMore && (
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            <LoadingResourceSpinner />
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "messageStore",
    "layoutStore",
    "modalStore",
    "profileStore",
    "routeStore"
  )(observer(MessageCenterConversationList))
);
