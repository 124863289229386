import { TBackgroundColor } from "tailwindcss-classnames";
import { SharedMediaTab } from "./types";
import axios from "axios";

export const InitialData = {
  conversations: [],
  selectedConversations: [],
  selectedContacts: [],
  contacts: [],
  csContacts: [],
  csConversation: null,
  isComposing: false,
  isSearchingView: false,
  isDeleting: false,
  isBulkMessageView: false,
  activeConversationMessages: [],
  message: "",
  contactsSearchTerm: "",
  conversationsSearchTerm: "",
  contactsFilterTypes: [],
  nextContactsUrl: "",
  isContactsLoading: false,
  isContactsLoadingMore: false,
  isSearchingContacts: false,
  nextMessagesUrl: "",
  futureMessagesUrl: "",
  isMessagesLoading: false,
  isMessagesLoadingMore: false,
  isActiveConversationMessagesRefreshing: false,
  nextConversationsUrl: "",
  isConversationsLoading: false,
  isConversationsLoadingMore: false,
  isSearchingConversations: false,
  searchingMessagesStartDate: "",
  isActiveConversationBlocked: false,
  activeConversationBlockedType: null,
  activeConversationBlockedCountryId: "",
  activeConversationBlockedCountryLabel: "",
  requestTimeout: null,
  contactsSearchTermReaction: undefined,
  conversationsSearchTermReaction: undefined,
  isMessagesMenuInNavbarOpen: false,
  unreadConversationMessagesCount: 0,
  activeConversation: null,
  activeSearchedWord: "",
  newsAndPolicies: [],
  audits: [],
  activeNewsAndPolicy: null,
  unreadNewsAndPolicyCount: 0,
  systemMessageUser: null,
  sendMessageError: null,
  attachmentsTobeSubmitted: [],
  activeConversationAttachments: [],
  activeConversationAttachmentsCount: 0,
  activeConversationAttachmentsLoading: false,
  activeMCSharedMediaModalTab: "all" as SharedMediaTab,
  nextAttachmentsUrl: "",
  attachmentsPendingUpload: [],
  sendMessageQueue: [],
  transcodingCancelTokenSource: axios.CancelToken.source(),
  sendMessageCancelTokenSource: axios.CancelToken.source(),
  messageCharCount: 0,
};
export const SEARCH_POLLING_TIMEOUT_IN_SECS = 1;
export const SEARCH_START_REQUEST_AFTER_N_LETTERS = 1;
export const INITIAL_MESSAGE_FONT_SIZE = "text-sm";
export const MESSAGE_CENTER_AUDIT_NOTIFICATION_ID = "2257-audit-id";
export const MSG_CENTER_MAX_IMAGE_SIZE = 20;
export const MSG_CENTER_MAX_VIDEO_SIZE = 500;
export const MSG_CENTER_MIN_VIDEO_DURATION_IN_SECONDS = 30;
export const MSG_CENTER_MAX_VIDEO_DURATION_IN_SECONDS = 600;
export const MSG_CENTER_MIN_VIDEO_WIDTH = 240;
export const MSG_CENTER_MIN_AUDIO_DURATION_SECONDS = 10;
export const MSG_CENTER_MAX_AUDIO_DURATION_MINUTES = 3;
export const MESSAGES_PER_REQUEST = 20;
export const MESSAGE_ATTACHMENT_TOKEN_PRICES = [
  10, 15, 20, 25, 30, 35, 40, 45, 50,
];
export const ATTACHMENTS_LIST_PAGE_SIZE = 20;
export const MESSAGE_LIST_REFRESH_DELAY_IN_MS = 3000;
export const MESSAGE_THUMBNAIL_REFRESH_MAX_ATTEMPTS = 5;

export const MC_CS_AGENT_PROFILE_IMG = "https://img.securedataimages.com/images/cams/support_images/female_support_wbg_cams.svg";

export enum MessageTabEnum {
  MESSAGE = "MESSAGE",
  POLICY = "POLICY",
}

export enum LoyalFanFiltersEnum {
  fan_club = "fanclub_members",
  bounty = "bounty_members",
  top_admirer = "top_admirers",
  top_spenders = "top_spenders",
  recent_visitors = "recent_visitors",
}

export const LoyalFanFiltersKey = {
  fanclub_members: ["is_fanclub_member"],
  top_admirers: ["is_top_admirer"],
  top_spenders: ["is_top_spender"],
  recent_visitors: ["is_recent_visitor"],
  bounty_members: ["is_bounty_member", "is_referral_member"],
  admin: ["is_messaging_cs"]
};

export const LOYAL_FANS_FILTERS = [
  {
    name: "Bounty/Referral",
    custom_label: "B/R",
    background: "bg-lightPink-bg-color" as TBackgroundColor,
    type: "bounty_members",
  },
  {
    name: "Fan Club",
    background: "bg-lightGreen-bg-color" as TBackgroundColor,
    type: "fanclub_members",
  },

  {
    name: "Recent Visitors",
    background: "bg-lightGray-bg-color" as TBackgroundColor,
    type: "recent_visitors",
  },
  {
    name: "Top Spenders",
    background: "bg-lightPurple-bg-color" as TBackgroundColor,
    type: "top_spenders",
  },
  {
    name: "Top Admirer",
    background: "bg-lightRed-bg-color" as TBackgroundColor,
    type: "top_admirers",
  },
  {
    name: "Support",
    background: "bg-lightRed-bg-color" as TBackgroundColor,
    type: "admin",
  },
];

export const ConversationBlockedErrors = {
  member: "you_blocked_member_from_contacting_you",
  country: "you_blocked_member_country_from_contacting_you",
  state: "you_blocked_member_state_from_contacting_you",
};

export const DELETE_CONVERSATION_CONFIRMATION_WORD = "remove";
export const DELETE_CONVERSATION_INPUT_IDENTIFIER = "matchingString";

export const ACCEPTED_IMAGES = ["image/jpeg", "image/jpg", "image/png"];

export const ACCEPTED_ATTACHMENTS = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "video/avi",
  "video/mpeg",
  "video/mpg",
  "video/quicktime",
  "video/mp4",
  "video/x-matroska",
  ".mkv",
];
